.faq-page {
    padding: 25px 0 75px;

    .content-header {
        margin: 0 auto 35px;
        h1 {
            font-weight: 500;
        }
    }

    .content-body {
        max-width: 700px;
        margin: 0 auto;
        border: 1px solid $gray;
        padding: 15px 25px;
        background: #fff;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.08),
            0 8px 15px -8px rgba(0, 0, 0, 0.15);
        h2 {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: $font-desktop-large;
            color: lighten($light-text-color, 20%);
            margin-bottom: 15px;
        }
    }
}

.faq {
    margin: 0 0 45px;
    padding: 0;

    .faq__item {
        margin: 0 0 10px;
        padding: 0;
        list-style: none;
        .faq__item-title {
            position: relative;
            padding: 10px 0 10px 40px;
            cursor: pointer;
            font-weight: 500;
            font-family: Roboto, sans-serif;
            color: $text-color;
            font-size: $font-desktop * 1.1;
            line-height: 125%;
            .toggle-icon {
                position: absolute;
                top: calc(50% - 1px);
                left: 0;
                display: inline-block;
                height: 3px;
                width: 20px;
                background: #ddd;
                border-radius: 4px;
                .rotating {
                    display: inline-block;
                    border-radius: 4px;
                    position: absolute;
                    width: 3px;
                    height: 20px;
                    left: 9px;
                    top: -9px;
                    background: #ddd;
                }
            }
        }
        p {
            line-height: 155%;
            font-size: $font-desktop;
            color: $light-text-color;
            font-family: Roboto, sans-serif;
            padding-left: 40px;
            display: none;
        }
    }
}
