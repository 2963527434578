.filter-widget-container {
    //min-height: 45px;
    position: relative;
    margin-bottom: 10px;
    z-index: 5;
}

.filter-widget {
    @extend %shadow;
    //position: absolute;
    background: #fff;
    .filter-widget__content {
        padding: 0 1rem 1rem;
        margin-top: 10px;

        & > div:not(:last-child) {
            margin-bottom: 10px;
        }
        h4 {
            font-weight: 500;
            margin-bottom: 5px;
            font-size: $font-desktop-small;
            color: rgba($light-text-color, 0.7);
        }

        .controls {
            margin-top: 15px;
            button {
                margin-right: 5px;
            }
        }
        .categories,
        .payment-methods,
        .statuses {
            & > div {
                display: flex;
                flex-wrap: wrap;
            }
            .option {
                border: 1px solid $border-color;
                padding: 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: $border-radius;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                position: relative;
                display: flex;
                align-items: center;
                flex: 0 1 160px;
                //height: 50px;
                //width:160px;
                &:hover {
                    @extend %shadow;
                }
                &.active {
                    box-shadow: inset 0 0 0 2px $green;
                    border-color: transparent;
                }
                .image-wrapper {
                    width: 25px;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        display: inline-block;
                        max-width: 100%;
                        max-height: 100%;
                        height: 25px;
                    }
                    svg {
                        font-size: $font-desktop-large;
                        color: $blue;
                    }
                }
                span {
                    font-size: $font-desktop-extra-small;
                    color: $light-text-color;
                    line-height: 130%;
                }
            }
        }

        .search,
        .dates {
            .date-errors {
                padding: 20px 10px 0;
                p {
                    margin: 0;
                    font-size: $font-desktop-extra-small;
                    color: $red;
                }
            }
            label {
                position: relative;
                display: block;
                margin-right: 5px;
                .clear {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    right: 10px;
                    top: 50%;
                    text-align: center;
                    background: $spanish-blue;
                    color: #fff;
                    transform: translateY(-50%);
                    font-size: $font-desktop-extra-small;
                    opacity: 0.65;
                    cursor: pointer;
                    transition: opacity 0.2s ease-in-out;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            input {
                border: none;
                box-shadow: none;
                background: $light-spanish-blue;
                padding: 0 15px;
                height: 32px;
                border-radius: 15px;
                display: inline-block;
                outline: none;
                font-size: $font-desktop-small;
                color: $light-text-color;
                max-width: 100%;
                font-weight: bold;
            }
        }

        .dates {
            display: flex;
            input {
                width: 150px;
            }
        }

        .search {
            & > div {
                display: flex;
            }
            .search-trigger {
                background: darken($light-spanish-blue, 1%);
                padding: 0 20px;
                height: 32px;
                border-radius: 15px;
                display: inline-block;
                font-size: $font-desktop-small;
                line-height: 32px;
                color: $light-text-color;
                cursor: pointer;
                &:hover {
                    background: darken($light-spanish-blue, 4%);
                }
            }
            label {
                max-width: 100%;
                display: inline-block;
                flex: 0 1 450px;
            }
            input {
                width: 100%;
            }
        }
    }

    .filter-widget__top {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        width: 100%;
        background: $light-spanish-blue;
        .applied-filters {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: $font-desktop-small;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding-right: 15px;
            .applied-filters-badge {
                display: flex;
                margin-right: 5px;
                margin-bottom: 4px;
                padding: 2px 8px;
                border-radius: 15px;
                color: #fff;
                align-items: center;
                font-size: $font-desktop-extra-small;
                white-space: nowrap;
                flex-shrink: 0;
                &.search {
                    background: $blue;
                }
                &.status {
                    background: $dark;
                }
                &.payment {
                    background: darken($light-spanish-blue, 35%);
                }
                &.category {
                    background: $green;
                }
                &.tag {
                    background: darken(#eee, 25%);
                }
                svg {
                    display: inline-block;
                    margin-left: 3px;
                    cursor: pointer;
                }
            }
        }
        .filter-toggle-btn {
            font-size: $font-desktop-extra-small;
            color: $text-color;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            svg {
                margin-left: 3px;
            }
        }
    }
}
