/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 23px;
    right: 15px;
    top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #fff;
    height: 3px !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 25px;
    width: 25px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #fff;
    height: 20px !important;
}

/* General sidebar styles */
.bm-menu {
    background: $blue;
    padding: 2.5rem 0 1rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.bm-list {
    top: 0;
    height: 100vh !important;

    .user-block {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 25px;
        display: flex !important;
        justify-content: center;
        background: darken($blue, 10%);

        .bm-list_btn {
            background: none;
            font-size: 1rem;
            padding: 0;
            color: rgba(#fff, 0.75);
            border-radius: $border-radius;
            border: none;
            box-shadow: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            &.login {
                background: rgba(#fff, 0.85);
                color: $dark;
                padding: 8px 15px;
                &:hover {
                    text-decoration: none;
                    background: #fff;
                }
            }
        }
        .profile-link {
            display: flex;
            align-items: center;
            font-family: 'Gotham Pro', Roboto, sans-serif;
            margin-right: 10px;
            img {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
            span.link {
                display: inline-block;
                color: #fff;
                font-family: 'Gotham Pro', Roboto, sans-serif;
            }
        }

        a {
            color: #fff;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        text-align: right;
        list-style: none;
        li {
            .navigation-item {
                cursor: pointer;
                color: #fff;
                font-size: 1rem;
                font-family: 'Gotham Pro', Roboto, sans-serif;
                padding: 10px 25px;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .navigation-item__badge {
                    display: inline-block;
                    background: #fff;
                    width: 30px;
                    height: 28px;
                    line-height: 29px;
                    border-radius: 50%;
                    text-align: center;
                    color: $blue;
                    font-size: $font-desktop-extra-small;
                    font-weight: 500;
                    margin-left: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.active {
                    background: darken($blue, 20%);
                }
                &:hover,
                &:active {
                    background: darken($blue, 10%);
                }
            }
        }
    }
}
