@import '../../../assets/css/atoms';
@import '../../../assets/css/variables';

.esc-dropdown-button-wrapper {
    position: relative;
}

.esc-button {
    @extend .flex;
    @extend .ai-center;
    height: 32px;
    font-size: 1rem;
    font-weight: 500;
    color: $text-color-gray;
    padding: 0 16px;
    width: max-content;
    cursor: pointer;
    transition: 300ms;

    &.sm {
        height: 28px;
        font-size: 0.95rem;
        padding: 0 10px;
    }

    &.raised {
        background-color: $lighten-blue;
        color: #fff;
        box-shadow: 0 3px 0 0 $shadow-blue;

        &:hover {
            background-color: $light-blue;
        }
    }

    &.active {
        background-color: $darken-blue;

        &:hover {
            background-color: $darken-blue;
        }
    }
}

.esc-button-dropdown {
    @extend .flex;
    @extend .fd-column;
    width: 100vw;
    max-width: 380px;
    position: absolute;
    visibility: visible;
    left: 0;
    top: 28px;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 0 3px 3px;
    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.1);
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 300ms ease-out;

    &.hidden {
        visibility: hidden;
        transform: scaleY(0);
    }

    &__item {
        @extend .flex;
        @extend .ai-center;
        cursor: pointer;
        height: 48px;
        padding: 0 16px;
        font-weight: 500;
        color: $text-color-gray;
        transition: background-color 300ms 100ms;

        &:hover {
            background-color: $gray;
        }

        & > a {
            color: inherit;
        }

        & > span,
        a {
            margin-left: 8px;
            font-size: 16px;
            flex-grow: 1;

            &:first-child {
                margin-left: 0;
            }
        }

        & > svg:last-child {
            margin-right: -16px;
        }

        &.group:hover div.esc-button-dropdown-horizontal {
            transform: scaleX(1);
            transition: transform 100ms ease-in 100ms;
        }
    }
}

.esc-button-dropdown-horizontal {
    @extend .flex;
    @extend .fd-column;
    width: max-content;
    position: absolute;
    left: calc(100% + 1px);
    top: calc(100% - 288px);
    background-color: #fff;
    border: 1px solid $border-color;
    border-left: none;
    border-radius: 0 3px 3px 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 100ms ease-out 100ms;
}
