.entity-info {
    padding: 1.5rem;
    .text-block {
        padding: 15px 0;
    }
    .select-option {
        font-weight: 500;
        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    .content-header {
        span {
            font-size: $font-desktop-small;
            i {
                font-style: normal;
                color: $light-text-color;
                display: inline-block;
                margin-right: 3px;
            }
        }
    }
    .daysCount {
        &.cropped {
            flex: 0 0 60px;
        }
    }
    .date {
        &.cropped {
            flex: 0 0 85px;
            margin-left: 10px !important;
        }
    }
    div.amount {
        &.cropped {
            label {
                text-align: left;
            }
            flex-grow: 1;
        }

        label {
            text-align: right;
            display: block;
            width: 100%;
        }
        .deal-info__value {
            justify-content: flex-end;
        }

        input {
            text-align: right;
        }
    }

    .previous-value {
        text-decoration: line-through;
        opacity: 0.8 !important;
        color: $red !important;
        margin-right: 4px;
    }

    h2 {
        font-size: $font-desktop-small;
        color: $blue;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0 0 8px;
    }
    p {
        margin: 0;
        color: $text-color;
        font-size: $font-desktop-small;
        line-height: 150%;
    }

    .template-name {
        display: block;
        padding: 0 0 2px;
        font-size: $font-desktop-small;
        border-bottom: 1px dotted $border-color;
        cursor: pointer;
        svg {
            color: $green;
        }
    }

    //.deal-info__header {
    //  list-style: none;
    //  margin: 0 0 25px;
    //  padding: 0;
    //  text-align:center;
    //
    //  & > li {
    //    line-height: 100%;
    //    margin-bottom: 2px;
    //  }
    //  .deal-info__name {
    //    h1 {
    //      font-size: $font-desktop-large;
    //      text-transform: uppercase;
    //      margin: 0;
    //      color: $blue;
    //      font-weight: 500;
    //    }
    //    span {
    //      font-size: $font-desktop-extra-small;
    //      color: $light-text-color;
    //    }
    //    span.badge {
    //      margin: 0;
    //    }
    //  }
    //
    //  .create-date {
    //    font-size: $font-desktop-extra-small;
    //    color: $light-text-color;
    //    line-height: 150%;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    span:first-of-type {
    //      margin-right: 5px;
    //    }
    //    .create-date__icon {
    //      font-size: $font-desktop-small;
    //      margin-right: 2px;
    //      color: rgba($light-text-color, .5);
    //    }
    //
    //  }
    //}
}

.entity-controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .buttons {
        flex-grow: 1;
    }

    .notification {
        display: flex;
        align-items: flex-start;
        padding: 5px 10px;
        border-radius: $border-radius;
        width: 100%;
        margin-bottom: 5px;

        &.notification_outgoing {
            border: 2px solid $green;
        }
        &.notification_incoming {
            border: 2px solid $blue;
        }
        svg {
            margin-right: 5px;
            color: $blue;
            position: relative;
            top: 2px;
            flex-shrink: 0;
            font-size: $font-desktop;
        }
        a {
            font-size: $font-desktop-extra-small;
            color: $blue;
            cursor: pointer;
        }
        p {
            color: $text-color;
            font-size: $font-desktop-extra-small;
            line-height: 120%;
        }
    }

    button {
        margin-right: 5px;
        &.accept-button {
            &:not([disabled]) {
                background-color: $btn-accept !important;
            }
        }
        &.decline-button {
            background: $dark !important;
            color: #fff !important;
        }
    }
}

.bordered-segment {
    background: #fff;
    border-radius: $border-radius;
    //border: 5px solid $border-color;
    box-shadow: 0 1px 10px $border-color, 0 0 15px $border-color;

    .error-field {
        position: relative;
        input {
            border-bottom-color: $red !important;
            color: $red !important;
        }
        .error {
            font-size: $font-desktop-extra-small;
            background: #fff;
            position: absolute;
            bottom: -2px;
            left: 0;
            display: inline-block;
            width: 250px;
            line-height: 110%;
            padding: 0;
            transform: translateY(100%);
        }
    }
    .select-option {
        flex-direction: column;
        align-items: flex-start;
        p {
            display: flex;
            align-items: center;
            font-size: $font-desktop;
        }
        i {
            margin: 0;
        }
    }
    .user {
        display: flex;
        align-items: flex-start;
        .chat-btn {
            padding: 2px 5px;
            background: $gray;
            font-size: $font-desktop-extra-small;
            cursor: pointer;
            color: $light-text-color;
            &:hover {
                background: darken($gray, 2%);
                color: $text-color;
            }
        }

        .image-wrapper {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            flex-shrink: 0;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 50%;
            }
            .initiator-badge {
                display: flex;
                top: auto;
                position: absolute;
                bottom: -2px;
                right: -3px;
                font-size: $font-desktop-medium * 1.1;
            }
        }
        .user__info {
            width: calc(100% - 60px);
            display: flex;
            flex-direction: column;

            a,
            p {
                line-height: 140%;
                max-width: 100%;
                display: inline-block;

                font-family: Roboto, Helvetica, Arial, sans-serif;
            }
            .user__profile-link {
                color: $text-color;
                font-size: $font-desktop;
                font-weight: 500;
                display: flex;
                align-items: center;
                span.name {
                    margin-right: 5px;
                }
                span.badge {
                    font-size: 0.7rem !important;
                    font-weight: 300;
                    text-transform: none;
                    padding: 2px 4px;
                    margin: 0;
                    display: inline-block;
                }
                span.name:hover {
                    text-decoration: underline;
                }
            }
            .user__score {
                display: flex;
            }
            .user__login {
                font-size: $font-desktop-small;
                color: $light-text-color;
                font-weight: 300;

                & > div {
                    cursor: pointer;
                    display: flex;
                    margin-right: 6px;
                }
            }

            p {
                font-size: $font-desktop;
                color: $text-color;
                font-weight: 500;
            }
        }
    }

    .deal-alert-message {
        padding: 10px;
        border: 2px solid $red;
        border-radius: 3px;
        width: 100%;
        max-width: 650px;
        font-weight: 400;
        color: black;
        margin-bottom: 10px;
        p {
            margin-bottom: 5px;
        }
        a {
            cursor: pointer;
            display: inline-block;
            margin-right: 5px;
        }
    }
    .entity-header {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $border-color;
        h1 {
            margin: 0;
            font-size: $font-desktop-large;
            color: $blue;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            .create-date {
                font-size: $font-desktop-small;
                color: $light-text-color;
                margin: 0;
                font-weight: 300;
            }
        }
        .dispute-date {
            font-size: $font-desktop-small;
            color: $light-text-color;
            margin: 0 0 0 7px;
            font-weight: 300;
            display: inline-block;
            vertical-align: middle;
        }
        .message {
            margin-top: 10px;
        }
        .category-name {
            margin: 0;
            text-transform: uppercase;
            color: $light-text-color;
            font-size: $font-desktop-small;
            letter-spacing: 0.8px;
        }
        & > div[data-react-toolbox='chip'] {
            margin-right: 8px;
        }
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        .item {
            padding-right: 15px;
            flex: 2 0 33%;
            & > div {
                max-width: 100%;
            }
        }
    }

    .deal-info__hr {
        margin-bottom: 2rem;
        margin-top: 0;
    }

    .deal-info__item {
        margin-bottom: 2rem;

        &.description {
            b,
            strong {
                font-weight: bold;
            }
            .descToggle-link {
                font-size: $font-desktop-small;
            }
        }
        input[type='number'] {
            text-align: right;
        }
        strike {
            color: $red !important;
            margin-right: 4px;
            display: inline-block;
        }
        .particle {
            color: $light-text-color;
            font-size: $font-desktop-small;
        }

        .row {
            display: flex;
            align-items: center;
        }
        .commission {
            font-size: $font-desktop-extra-small;
            color: $light-text-color;
        }
        .dates {
            display: flex;
            align-items: center;
            svg {
                color: $blue;
                font-size: $font-desktop-small;
                margin-right: 2px;
            }
            input[type='number'] {
                width: 50px;
                margin-left: 5px;
            }
            [data-react-toolbox='input'] {
                padding: 0;
                margin-left: 5px;
                width: 85px;
            }
            .deal-info__item-value {
                margin: 0 4px;
            }
        }

        .deal-info__item-label {
            font-size: $font-desktop-extra-small;
            color: $light-text-color;
        }
        .deal-info__item-value {
            font-weight: 500;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            color: $text-color;
            font-size: $font-desktop;
        }

        .deal-info__input-wrap-narrow {
            margin-right: 5px;
        }

        input[type='number'],
        input[type='text'],
        select {
            border: none;
            border-bottom: 1px solid $border-color;
            display: inline-block;
            margin-right: 5px;
            outline: none;
            padding-bottom: 5px;
            color: $text-color;
            line-height: 100%;
            height: 28px;
        }
        input[type='number'] {
            width: 100px;
        }
        input[type='text'] {
            width: 100%;
            display: block;
        }
    }
    .ui-strike {
        color: $red;
        margin-right: 4px;
        display: inline-block;
        vertical-align: top;
        text-decoration: line-through;
    }
}
