.flex {
    display: flex;
    max-width: 100%;
    &.flex_horizontally-centered {
        justify-content: center;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.flex_end {
        justify-content: flex-end;
    }
    &.flex_vertically-centered {
        align-items: center;
    }
}
