.header-menu {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 199;
    width: 100%;
    background: $blue;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    height: 50px;
    padding: 0 15px;

    .logo-wrapper {
        flex-basis: 200px;
        flex-grow: 1;
        width: 200px;

        .logo {
            display: block;
            width: 125px;
            height: 25px;
        }
        img {
            max-width: 100%;
        }
    }

    .user-block {
        display: flex;
        align-items: center;
        flex-basis: 200px;
        flex-grow: 1;
        width: 250px;
        justify-content: flex-end;
        .button-toolbar {
            margin-right: 10px;
            justify-content: flex-end;
        }
        .icon-menu {
            button {
                color: #fff;
            }
            ul {
                li {
                    span:first-of-type {
                        color: $blue;
                    }
                }
            }
        }
        & > a {
            color: #fff;
            font-size: $font-desktop;
            font-family: Roboto, sans-serif;
            font-weight: 300;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user-pic {
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-right: 5px;
            border-radius: 50%;
        }
    }
    .menu-block {
        display: flex;
        flex-grow: 1;
        min-width: 200px;
        align-items: center;
        justify-content: center;

        & > .esc-dropdown-button-wrapper > .esc-button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        & > .navigation-item {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .navigation-item__counter {
        padding: 10px;
        background: #000;
        color: #fff;
    }
    .navigation-item {
        display: inline-block;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: $border-radius;
        padding: 0 10px;
        height: 28px;
        color: rgba(#fff, 0.95);
        font-family: Roboto, sans-serif;
        font-size: $font-desktop;
        transition: all 0.2s ease-in-out;
        line-height: 28px !important;
        position: relative;
        font-weight: 300;
        &.how-it-works {
            svg {
                position: relative;
                top: 3px;
            }
        }
        &.login {
            background: darken($dark-blue, 5%);
            white-space: nowrap;
            &:hover {
                background: darken($dark-blue, 7%);
            }
        }

        &.active {
            background: darken($dark-blue, 4%);
            color: #fff;
            &:hover {
                background: darken($dark-blue, 4%);
            }
        }
        &:hover {
            color: #fff;
            text-shadow: 0 0 2px rgba(#fff, 0.6);
        }

        &.new-deal-button {
            background: lighten($blue, 3%);
            font-weight: 500;
            border-radius: $border-radius;
            color: #fff;
            box-shadow: 0 3px 0 0 #03679e;
            &:hover {
                background: lighten($blue, 4%);
                box-shadow: 0 2px 0 0 #03679e;
                text-shadow: none;
            }
        }
    }

    .menu {
        position: relative;
        margin-right: 10px;
        font-family: 'Gotham Pro', Roboto, sans-serif;
        font-size: $font-desktop;
        .menu-dropdown {
            padding: 15px 10px;
            text-align: center;
            position: absolute;
            box-shadow: 0 2px 15px -5px rgba(#000, 0.1),
                0 2px 10px 0 rgba(#000, 0.1);
            width: 250px;
            bottom: calc(-100% + 10px);
            left: 50%;
            transform: translate3d(-50%, 100%, 0);
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            .badge {
                background: $blue;
                display: inline-block;
                font-weight: 400;
                color: #fff;
                padding: 3px 5px;
                margin-left: 5px;
                font-size: $font-desktop-extra-small;
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -7px;
                left: 50%;
                border: {
                    color: transparent;
                    style: solid;
                    bottom-width: 5px;
                    bottom-color: #fff;
                    left-width: 5px;
                    right-width: 5px;
                }
                transform: translateX(-50%);
            }
            a {
                display: inline-block;
                cursor: pointer;
                padding: 8px 10px;
                text-align: center;
                color: $text-color;
                font-size: $font-desktop;
                border-radius: $border-radius;
                transition: background 0.2s ease-in-out;
                font-family: Gotham Pro, Roboto, sans-serif;
                font-weight: 500;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }

                &:not(.new-deal-button):hover {
                    background: $gray;
                }
                &.active {
                    background: $gray;
                    &:hover {
                        background: $gray;
                    }
                }
            }
        }
    }
    a {
        color: #fff;
    }
}

.user-block {
    position: relative;
    .user-accounts-wrapper {
        & > a {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: $font-desktop;
            svg {
                margin-left: 2px;
                position: relative;
                top: 1px;
            }
        }
        .user-accounts {
            background: #fff;
            position: absolute;
            top: 30px;
            right: -5px;
            border-radius: $border-radius * 2;
            @extend %shadow;
            width: 380px;
            overflow: hidden;
            .user-accounts__item {
                padding: 12px 32px 12px 20px;
                display: flex;
                background: #fff;
                cursor: default;
                position: relative;
                & > svg {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: lighten($light-text-color, 5%);
                    &:hover {
                        color: $text-color;
                    }
                }

                .user-info {
                    flex-grow: 1;
                }

                &:not(.active) {
                    background: lighten($gray, 2%);
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        background: darken($gray, 2%);
                    }
                }
                .user-pic {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color;
                }
                .buttons-block {
                    .link {
                        display: inline-block;
                        flex-shrink: 0;
                        padding: 4px 10px;
                        font-size: $font-desktop-extra-small;
                        border-radius: $border-radius;
                        margin-top: 2px;
                        line-height: 130%;
                        &.blue {
                            color: #fff;
                            background: $blue;
                            &:hover {
                                background: darken($blue, 3%);
                            }
                        }
                        &.dark {
                            color: #fff;
                            background: $dark;
                            margin-left: 3px;
                            &:hover {
                                background: darken($dark, 3%);
                            }
                        }
                        &.impersonation {
                            background: none;
                            padding: 0;
                            border-bottom: 1px solid
                                lighten($light-text-color, 5%);
                            color: lighten($light-text-color, 5%);
                            margin-top: 5px;
                            border-radius: 0;
                            transition: all 0.2s ease-in-out;
                            &:hover {
                                color: $text-color;
                            }
                        }
                    }
                }

                p {
                    margin: 0;
                    font-size: $font-desktop-small;
                    &.user-name {
                        color: $text-color;
                    }
                    &.user-login {
                        color: lighten($light-text-color, 2%);
                    }
                }
            }
        }
    }
}
