form {
    .form-group,
    .checkbox,
    .radio {
        label {
            font-weight: 300;
            font-size: $font-desktop;
        }
    }
    textarea {
        resize: vertical;
    }
    p.error {
        color: $red;
        padding: 2px 0;
        margin: 0;
        font-size: $font-desktop-small;
    }
    .rdt.invalid {
        input {
            border-color: $red;
            background: #fff;
            //color:$red;
        }
    }

    .form-control {
        background: $pristine-input-bg;
        border-color: $pristine-input-bg;
        box-shadow: none;
        padding: 10px;
        border-radius: $border-radius;
        height: $input-height;
        line-height: 100%;
        font-size: $font-desktop;
        &.invalid {
            border-color: $red;
            background: #fff;
            //color:$red;
            &:focus {
                box-shadow: 0 0 7px rgba(red, 0.3);
            }
        }
        &.important {
            input {
                font-weight: bold;
                color: $blue;
            }
        }
        &[disabled] {
            background-color: darken($pristine-input-bg, 5%);
        }
    }
    textarea.form-control {
        height: 100px;
        resize: vertical;
    }
    .btn {
        border-radius: 2px;
        height: 45px;
        display: inline-block;
    }
    .help-block {
        color: $light-text-color;
    }
    .vertical-aligned {
        display: flex;
        align-items: center;
    }
}

button.button {
    display: inline-block;
    border: none;
    box-shadow: none;
    padding: 8px 20px;
    border-radius: 2px;
    font-size: $font-desktop;
    &[disabled] {
        opacity: 0.3;
        pointer-events: none;
    }
    &.btn-medium {
        padding: 6px 8px;
        height: auto;
        font-size: $font-desktop;
    }
    &.btn-large {
        padding: 10px;
        font-size: $font-desktop-medium;
    }
    &.btn-red {
        background: $red;
        color: #fff;
        &:hover {
            background: darken($red, 3%);
        }
    }

    &.btn-blue {
        background: $blue;
        color: #fff;
        &:hover {
            background: darken($blue, 3%);
        }
    }
    &.btn-white {
        background: #fff;
        color: $text-color;
        &:hover {
            background: $gray;
        }
    }
    &.btn-gray {
        background: $gray;
        color: $text-color;
        &:hover {
            background: darken($gray, 4%);
        }
    }
}

.new-offer-form {
    margin-bottom: 35px;
    .new-offer-form__payment-methods {
        font-size: $font-desktop;
    }
    .new-offer-form__progress-bar {
        margin: 15px 0 25px;
    }
    .progress-wrapper {
        text-align: center;
    }
}

.tooltip-icon {
    margin-left: 5px;
}

.card-form {
    .form-inline {
        width: 100%;
        display: flex;
        & > .form-group {
            flex-grow: 1;
            justify-content: space-between;
            &.valid-thru {
                display: flex;
                justify-content: space-between;
                margin-right: 20px;
                //align-items: center;
                & > div {
                    flex-basis: 50%;
                    margin-right: 10px;
                }
            }
            &.cvc {
                width: 150px;
                flex-grow: 0;
            }
        }
    }
}
