.button-toolbar {
    display: flex;
    .button-toolbar__icon {
        position: relative;
        cursor: pointer;
        border: none;
        box-shadow: none;
        outline: none;
        background: none;

        i {
            display: inline-block;
        }
        svg {
            position: relative;
            z-index: 1;
            font-size: $font-desktop-medium;
        }
    }
    &.rhombus {
        margin: 10px 0 25px;

        .button-toolbar__icon {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            svg {
                font-size: $font-desktop-large;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }

            i {
                width: 20px;
                height: 20px;
                position: relative;
                z-index: 2;
            }
            &:before {
                position: absolute;
                z-index: 0;
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                transform: rotate(45deg);
                border-radius: 12px;
                left: 0;
                top: 0;
            }
            &.facebook {
                &:before {
                    background: darken(#4867aa, 5%);
                    opacity: 1;
                }
                margin-right: 15px;
                &:hover:before {
                    background: darken(#4867aa, 10%);
                }
                svg {
                    color: #fff;
                }
            }

            &.google {
                svg {
                    color: #fff;
                }
                &:before {
                    background: #dc483c;
                    opacity: 1;
                }

                &:hover {
                    &:before {
                        background: darken(#dc483c, 5%);
                    }
                }
            }
            &.webmoney {
                i {
                    background: url('../images/webmoney-white.svg') no-repeat
                        center;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    z-index: 5;
                }
                &.active:before {
                    background: $blue;
                    opacity: 1;
                }
                &.active:hover {
                    &:before {
                        background: darken($blue, 5%);
                    }
                }
            }
            &.vk {
                &:before {
                    background: #5e81a8;
                }
                &:hover:before {
                    background: darken(#5e81a8, 5%);
                }
                svg {
                    color: #fff;
                }
            }
        }
    }
    &.flat {
        flex-grow: 1;

        & > .button-toolbar__icon:not(:last-of-type) {
            margin-right: 10px;
        }

        .button-toolbar__icon {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius;
            margin-right: 10px;
            &.webmoney {
                background: $dark-blue;

                &:hover {
                    & {
                        background: darken($dark-blue, 5%);
                    }
                }
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 18px;
                    height: 18px;
                    background: url('../images/webmoney-white.svg') no-repeat
                        center;
                }
            }
            &.vk {
                background: darken(#5e81a8, 5%);
                svg {
                    font-size: $font-desktop-large;
                }
                &:hover {
                    background: darken(#5e81a8, 8%);
                }
            }
            &.facebook {
                background: darken(#4867aa, 5%);
                color: #fff;
                &:hover {
                    background: darken(#4867aa, 10%);
                }
            }
            &.google {
                background: #dc483c;
                color: #fff;
                &:hover {
                    background: darken(#dc483c, 5%);
                }
            }
        }
    }
}
