@import './atoms';

.esc-form {
    margin-bottom: 24px;

    &__button-group {
        width: 100%;
        @extend .flex;
        @extend .jc-between;
        padding-top: 24px;

        & button:not(:first-child):not(:last-child) {
            margin-left: auto;
        }

        & button:not(:last-child) {
            margin-right: 16px;
        }

        & button:last-child {
            margin-right: 0 !important;
        }

        &.end {
            @extend .jc-end;
        }
    }
}

div.esc-form-input.md {
    max-width: 448px;
}
