@mixin layout-xss {
    @media screen and (max-width: 479px) {
        @content;
    }
}
@mixin layout-xs {
    @media screen and (min-width: 480px) {
        @content;
    }
}
@mixin layout-sm {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin layout-md {
    @media screen and (min-width: 992px) {
        @content;
    }
}

@mixin layout-lg {
    @media screen and (min-width: 1200px) {
        @content;
    }
}
