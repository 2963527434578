.api {
    h3 {
        color: #3c4a5a;
        font-weight: 400;
    }
    .button-link {
        background: $blue;
        color: #fff;
        padding: 8px 10px;
        border-radius: $border-radius;
        display: inline-block;
        margin-top: 15px;
    }
    .menu {
        background: lighten($gray, 3%);
        border: 1px solid $border-color;

        .menu__item {
            padding: 10px;
            font-size: $font-desktop;
            display: block;
            font-weight: 300;
            color: $text-color;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid $border-color;
            }
            &:hover {
                color: $blue;
            }
            &.active {
                color: $blue !important;
            }
        }
    }

    .api-text-content {
        h2 {
            color: $blue;
            font-weight: 400;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        td {
            border: 1px solid#ddd;
            padding: 10px;
        }
        th {
            background: $blue;
            padding: 10px;
            color: #fff;
            border: 1px solid #fff;
        }
    }
}
