.list-item-enter {
    opacity: 0.01;
}

.list-item-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.list-item-leave {
    opacity: 1;
}

.list-item-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}
