//.chat-wrapper{
//  position: fixed;
//  right: 1.2rem;
//  bottom: 1.2rem;
//}

.chat-item {
    border: 1px solid $border-color;
    margin-bottom: 10px;
    display: flex;
    font-size: $font-desktop-small;
    justify-content: space-between;
    border-radius: $border-radius;
    transition: all 0.2s ease-in-out;
    background: #fff;
    box-shadow: 0 1px 4px rgba(37, 32, 31, 0.25);
    &:hover {
        box-shadow: 0 1px 6px rgba($light-text-color, 0.5);
    }

    &.unread {
        //background: #f5f5f5;
        //border: 1px solid darken($border-color, 3%);
        position: relative;
        overflow: hidden;
        background: #0288d11f;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 100%;
            width: 4px;
            background: $blue;
            left: -1px;
            top: 0;
        }

        .message_preview {
            font-weight: 500;
            .badge {
                margin-right: 5px;
            }
        }
    }

    .sender-data {
        display: flex;
        align-items: center;
        margin-right: 10px;

        img {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 5px;
        }
        b {
            font-weight: 500;
            color: $blue;
        }
    }
    .left-block {
        flex: 0 0 calc(100% - 100px);
        display: flex;
        align-items: center;
        padding: 0.5rem;
        cursor: pointer;

        & > span:not(:last-of-type) {
            margin-right: 25px;
        }
        .entity-info {
            width: 300px;
            padding: 0 10px;

            p {
                font-weight: 500;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .badge {
                display: inline-block;
                width: 100px;
                text-align: center;
            }
        }

        .time {
            text-align: center;
            line-height: 130%;
            color: $light-text-color;
            font-size: $font-desktop-extra-small;
            width: 80px;
            b {
                display: block;
                color: $text-color;
                font-weight: 500;
            }
        }
        .message_preview {
            margin: 0;
            max-width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            min-width: 100px;
            display: flex;
            align-items: center;
        }
    }
    .right-block {
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
            display: inline-block;
            white-space: nowrap;
        }

        .controls {
            span {
                font-size: $font-desktop-medium;
                border: 1px solid $border-color;
                background: #fff;
                color: lighten($light-text-color, 25%);
                width: 30px;
                height: 30px;
                border-radius: 50%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: lighten($light-text-color, 10%);
                }
            }
        }
    }

    .incoming-mail-icon {
        transform: rotate(180deg);
    }
}

.chat-widget {
    p.empty-chat {
        text-align: center;
        color: rgba($light-text-color, 0.6) !important;
        font-size: $font-desktop-small !important;
        display: block !important;
        font-weight: 300 !important;
    }
    .message__header {
        text-align: right;

        h4 {
            font-size: $font-desktop-small;
            color: $text-color;
            margin: 0 0 2px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
        }
    }
    .message-bubble {
        min-width: 150px;
        border-radius: $border-radius;
        background: #fff;
        float: right;
        position: relative;
        @extend %shadow;
        font-size: $font-desktop-small;

        .message-bubble__text {
            padding: 10px 10px 5px;
        }

        .message-bubble__footer {
            display: flex;
            justify-content: flex-end;
            padding: 0 5px 5px;
            align-items: center;
            .status {
                margin-left: 5px;
                position: relative;
                top: -1px;
                font-size: $font-desktop;
            }
            .timestamp {
                color: $light-text-color;
                font-size: $font-desktop-extra-small;
            }
        }
    }
    .message__body {
        display: flex;
        justify-content: flex-end;

        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .load-more-messages {
        text-align: center;
        margin-bottom: 10px;
        height: 25px;
        span {
            cursor: pointer;
            font-size: $font-desktop-extra-small;
            background: $spanish-blue;
            color: #fff;
            padding: 0.4rem 0.8rem;
            border-radius: 20px;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: darken($spanish-blue, 10%);
            }
        }
    }
    .chat-footer {
        background: darken($gray, 2%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;

        ::-webkit-input-placeholder {
            color: rgba($light-text-color, 0.6);
        }
        :-moz-placeholder {
            color: rgba($light-text-color, 0.6);
        }
        ::-moz-placeholder {
            color: rgba($light-text-color, 0.6);
        }
        :-ms-input-placeholder {
            color: rgba($light-text-color, 0.6);
        }
        textarea {
            border: none;
            box-shadow: none;
            outline: none;
            display: block;
            font-size: $font-desktop-small;
            flex-grow: 1;
            margin-right: 15px;
            color: $text-color;
            max-height: 150px;
            overflow-y: auto;
            padding: 10px;
        }
        a.send-message-btn {
            background: $dark-blue;
            display: flex;
            color: #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            justify-content: center;
            align-items: center;
            outline: none;
            box-shadow: none;
            border: none;
            font-size: $font-desktop-extra-small;
            flex-shrink: 0;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:not(.disabled):hover {
                background: darken($dark-blue, 2%);
            }
            &.disabled {
                background: darken($border-color, 3%);
                cursor: default;
            }
        }
    }
    .chat-message {
        margin-bottom: 15px;

        &.unread {
            .status {
                color: rgba($dark, 0.3);
            }
        }

        &.read {
            .status {
                color: $blue;
            }
        }

        &.incoming {
            .message__header {
                text-align: left;
            }
            .message__body {
                justify-content: flex-start;
                .message-bubble {
                    color: #fff;
                    box-shadow: 0 1px 2px rgba(#000, 0.4);
                    float: left;
                    @extend %blueGradient;
                    .timestamp {
                        color: rgba(#fff, 0.8);
                    }
                }
            }

            &.unread {
                .status {
                    color: rgba(#fff, 0.5);
                }
            }
            &.read {
                .status {
                    color: #fff;
                }
            }
        }

        &.admin {
            border: none;
            .message__header {
                h4 {
                    color: $red !important;
                }
            }
            .message__body {
                .message-bubble {
                    background: $red;
                }
            }
        }
    }

    &.fullpage {
        border: 1px solid $border-color;
        border-top: none;
        @extend %shadow;

        .chat-scroll-wrapper {
            height: 45vh;
            background: #fff;
        }
        .chat-message {
            .message-bubble {
                max-width: 400px;
            }
        }
    }
    &.popup {
        position: fixed;
        box-shadow: 0 1px 10px rgba(#000, 0.2), 0 1px 5px -1px rgba(#000, 0.1);
        right: 10px;
        bottom: 10px;
        background: #fff;
        width: 400px;
        z-index: 100;
        border-radius: $border-radius;
        overflow: hidden;
        .chat-message {
            .message-bubble {
                //max-width: 85%;
            }
        }
        .chat-footer {
            border-top: 1px solid $border-color;
            background: #fff;
            textarea {
                padding: 10px 0;
            }
        }
        .chat-header {
            background: $dark-blue;
            text-align: center;
            font-size: $font-desktop-small;
            font-weight: 500;
            color: #fff;
            padding: 0.5rem 25px;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            position: relative;
            p {
                margin: 0;
                color: #fff;
                text-align: center;
                font-size: $font-desktop-small;
                line-height: 110%;
            }

            .close-btn {
                position: absolute;
                cursor: pointer;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                line-height: 100%;
                color: rgba(white, 0.8);
                transition: color 0.2s ease-in-out;
                &:hover {
                    color: white;
                }
            }
        }
        .chat-scroll-wrapper {
            height: 40vh;
            background: $gray;
        }
    }

    .chat-scroll-wrapper {
        overflow-y: scroll;
        padding: 1rem;
        position: relative;
    }
}

.chat-page-header {
    padding: 10px;
    background: $blue;
    position: relative;
    z-index: 10;
    border: {
        top-left-radius: $border-radius;
        top-right-radius: $border-radius;
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        //background: linear-gradient(to bottom, rgba(#000, .3), transparent);
        position: absolute;
        bottom: -5px;
        left: 0;
    }
    h1 {
        margin: 0;
        font-size: $font-desktop-large;
        font-weight: 500;
        color: #fff;
    }
    a {
        color: rgba(#fff, 0.7);
        font-size: $font-desktop-small;
        margin-right: 10px;
        &:first-of-type {
            color: #fff;
        }
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
