@import 'src/assets/css/layout';

$h3-xss: 1.2rem;
$h3-md: 1.28571429rem;
$text-xss: 1rem;

$h2-xss: 1.5rem;
$h2-sm: 1.8rem;
$h2-md: 2.18rem;

$size: 26px;
$speed: 3s;
$peakopacity: 0.6;
header {
    z-index: 600;
}
.landing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    & > div {
        width: 100%;
        padding: 70px 0 100px;
    }
    p {
        margin: 15px 0 30px;
    }
    p,
    li {
        color: $text-color;
        font-weight: 400;
        line-height: 1.4em;
        font-size: $text-xss;
    }
    h4,
    h3 {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.5px;
        font-size: $h3-xss;
        @include layout-md {
            font-size: $h3-md;
        }
    }
    h2 {
        font-size: $h2-xss;
        font-weight: 700;
        font-family: Roboto, sans-serif;
        position: relative;
        text-transform: uppercase;
        line-height: 115%;
        letter-spacing: 1px;
        margin-bottom: 2rem;
        max-width: 450px;
        @include layout-sm {
            margin-bottom: 2rem;
            font-size: $h2-sm;
            max-width: 550px;
        }
        @include layout-md {
            font-size: $h2-md;
        }
        @include layout-lg {
            margin-bottom: 4rem;
        }
        &.text-center {
            justify-content: center;
            align-items: center;
            margin: {
                left: auto;
                right: auto;
            }
        }

        strong {
            display: inline;
            color: $blue;
        }
    }
    .buyer-step__number {
        font-weight: 800;
        color: #c8e4f3;
        font-size: 70pt;
        position: absolute;
        bottom: 23px;
        left: 0;
        z-index: 1;
        opacity: 0.6;
    }

    .landing__promo {
        background: url(../images/escrow_promo.png) 0 -150px no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 108px;
        padding-bottom: 108px;

        & > .ui.container {
            a {
                font-weight: 500;
                color: #fff;
                text-align: center;
                padding: 0;
                border-bottom: 1px dashed #fff;
            }
        }
        .promo-block {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 4em;
            .promo-block__item {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                width: 25%;
                p {
                    font-family: 'Gotham Pro', sans-serif;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    line-height: 1.2;
                    color: #fff;
                }

                p.promo_text {
                    text-align: center;
                    font-size: $font-desktop-medium;
                }

                .promo-value {
                    font-size: $font-desktop-extra-large * 1.4;
                    color: $orange;
                    display: flex;
                    align-items: flex-end;
                    position: relative;
                    font-weight: 700;
                    small {
                        font-size: $font-desktop;
                        line-height: $font-desktop;
                        display: inline-block;
                        margin-left: 5px;
                    }
                    //&:before{
                    //  content: "";
                    //  display: block;
                    //  width: 2px;
                    //  height: 25px;
                    //  background: $orange;
                    //  position: absolute;
                    //  top: 79%;
                    //  left: 50%;
                    //}
                }
            }
        }

        h1 {
            color: #fff;
            font-size: 25px;
            font-weight: 400;
            margin-bottom: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 15px;
                font-size: 1.6rem !important;
            }
            span {
                @include landing-header;
                color: #fff;
                text-shadow: 0 13px 19px #29242470;
                display: block;
                margin-bottom: 15px;
                @media screen and (max-width: 768px) {
                    margin-bottom: 0;
                    font-size: 2.5rem;
                }
            }
        }
        button {
            width: 300px !important;
            height: 60px;
            text-transform: none;
            font-size: $font-desktop-medium;
            margin-bottom: 24px;
            &:hover {
                background: darken($blue, 3%);
            }
        }

        .arrows {
            display: none;
            position: absolute;
            bottom: 90px;
            left: 50%;
            width: $size;
            height: $size;
            transform: translate(-50%, -50%);
            @media screen and (min-width: 768px) {
                display: block;
            }
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
                border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
                transform: translate($size/3, $size * 4/3) rotate(-45deg);
                animation: arrows $speed linear infinite;
            }
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
                border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
                transform: translate($size * 2/3, 0px) rotate(-45deg);
                animation: arrows $speed linear infinite $speed/-2;
            }
        }

        .payment-methods {
            background: #2d7fbb;
            width: 100%;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 3;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            p {
                display: none;
                font-size: $font-desktop-large * 0.8;
                text-transform: uppercase;
                font-family: Roboto, sans-serif;
                font-weight: 500;
                line-height: 130%;
                color: #fff;
                margin: 0 20px 0 0;
                @include layout-md {
                    display: block;
                }
            }

            h3 {
                text-transform: uppercase;
                width: 350px;
                font-size: $font-desktop-large * 1.1;
            }
        }
    }
    .landing__description {
        margin: 0;
        padding: 50px 0;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0 2px 8px 2px #d7e2e9;
        position: relative;
        ul {
            padding-left: 2em;
            width: 95%;
            li {
                list-style-type: none;
                position: relative;
                margin-bottom: 15px;
                text-align: justify;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 2px;
                    background: $light-blue;
                    top: 7px;
                    left: -1.8em;
                }
            }
        }
        h2 {
            @include layout-lg {
                margin-bottom: 2rem;
            }
        }

        .description-text {
            display: block;
            width: 50%;
            text-align: left;
            p {
                text-align: justify;
                margin: 0;
                width: 95%;
            }
        }
        .description-image {
            display: block;
            width: 50%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            height: 100%;
            box-shadow: 0 2px 8px 2px #d7e2e9;
        }
    }
    .landing__advantage-block {
        padding-bottom: 70px;
        span {
            display: inline;
        }

        .advantage-block__item {
            display: flex;
            @include layout-md {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            .advantage-block__item-icon {
                flex-shrink: 0;
                position: relative;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                box-shadow: 4px 7px 14px 0 rgba(142, 173, 195, 0.45);
                background: linear-gradient(360deg, #fff, #ebebec);
                margin-right: 16px;
                @include layout-sm {
                    width: 70px;
                    height: 70px;
                }
                @include layout-md {
                    margin: 0 0 20px;
                    width: 80px;
                    height: 80px;
                }
                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include layout-sm {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .advantage-block__text {
            }
        }
    }
    .landing__benefit {
        padding: 0;
        margin-top: -27px;
        h2 {
            @include layout-sm {
                margin-bottom: 4rem;
            }
        }
        .benefit-block {
            margin-top: 30px;
        }
        span {
            display: inline;
        }
        p {
            margin: 0 auto 36px;
            max-width: 32%;
        }
        .benefit-top {
            background: #eff6fa;
            padding: 70px 0;

            .seller {
                .benefit-item__header {
                    background: linear-gradient(45deg, #ffb013, #f7a127);
                }
                .benefit-item__content {
                    ul {
                        li {
                            list-style: none;
                            span.benefit__icon {
                                background: url('../images/dispute/shield-yelow.svg')
                                    no-repeat;
                            }
                        }
                    }
                }
            }
        }
        .column {
            @include layout-sm {
                margin-bottom: 25px;
            }
        }

        .benefit-item {
            position: relative;
            margin-bottom: 25px;
            @include layout-sm {
                margin-bottom: 0;
                height: 100%;
            }
            .benefit-item__header {
                background: linear-gradient(45deg, #2ea9ed, #088dd6);
                color: #fff;
                padding: 1em 32px;
                max-height: 60px;
                text-align: center;
                position: absolute;
                white-space: nowrap;
                z-index: 5;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
            }
            .benefit-item__content {
                background: #fff;
                padding: 3em 1em 2em;
                box-shadow: 4px 7px 14px 0px rgba(142, 173, 195, 0.45);
                position: relative;
                border-radius: 3px;
                height: 100%;
                margin: 0;
                li {
                    display: flex;
                    list-style: none;
                    position: relative;
                    background-size: contain;
                    padding-left: 2.8em;
                    width: 95%;
                    text-align: left;
                    margin-bottom: 20px;
                    span.benefit__icon {
                        height: 40px;
                        display: inline-block;
                        figure {
                            display: inline-block;
                            width: 35px;
                            position: absolute;
                            left: 1px;
                            top: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
        .benefit-bottom {
            margin-bottom: 112px;
            .benefit-block {
                .benefit-item__bottom-header {
                    &::before {
                        content: '';
                        display: block;
                        width: 9px;
                        height: 9px;
                        background: #0478b8;
                        border-radius: 100%;
                        position: absolute;
                        top: -3px;
                        left: 49%;
                    }
                }
                .benefit-item__content {
                    ul {
                        padding: 0;
                        li {
                            list-style: none;
                            //background: url("../images/dispute/check-blue-01.svg") no-repeat;
                            margin-bottom: 20px;
                            //height: 4vh;
                        }
                    }
                }
                .seller {
                    //.benefit-item__content {
                    //  ul{
                    //    li{
                    //      list-style: none;
                    //      background: url("../images/dispute/check-01.svg") no-repeat;
                    //      margin-bottom: 20px;
                    //    }
                    //  }
                    //}
                    .benefit-item__bottom-header {
                        background: linear-gradient(45deg, #ffb013, #f7a127);
                        &::before {
                            background: #f09109;
                        }

                        .header-icon {
                            background: linear-gradient(
                                90deg,
                                #ffb013,
                                #f7a127
                            );
                        }
                    }
                }
            }
            .benefit-item__bottom-header {
                background: linear-gradient(45deg, #2ea9ed, #088dd6);
                color: #fff;
                width: 70%;
                min-height: 60px;
                text-align: center;
                position: relative;
                margin-bottom: -30px;
                margin-left: auto;
                margin-right: auto;
                z-index: 5;
                h3 {
                    text-transform: inherit;
                    margin: 6px 0 0 22%;
                    text-align: left;
                    line-height: 1.1;
                    padding: 8px 10px;
                }
                .header-icon {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(90deg, #2ea9ed, #088dd6);
                    height: 57px;
                    width: 76px;
                    figure {
                        display: block;
                        margin: 0 !important;
                        padding: 5px;
                        box-sizing: border-box;
                        height: 100%;
                        max-width: 18%;
                        position: absolute;
                        top: 2px;
                        left: 11px;
                        img {
                            display: block;
                            width: 2.5em;
                            height: 100%;
                        }
                    }
                }
            }
            .benefit-item__content {
                background: linear-gradient(45deg, #f9f9f9, #fff);
            }
        }
    }
    .landing__application {
        display: flex;
        align-items: center;
        background: #eff6fa;
        padding: 8vh 0;

        .item {
            background: #fff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            text-align: left;
            margin-bottom: 15px;
            color: $blue;
            height: auto;
            &:hover {
                box-shadow: 0 0 25px rgba(#8eadc3, 0.35);
            }

            .item-head {
                width: 100%;
            }
            figure {
                display: block;
                margin: 0 !important;
                padding: 0;
                height: 100%;
                width: 100%;
                overflow: hidden;
            }
            .item__body {
                display: block;
                width: 100%;
                padding: 1rem 2rem;
                min-height: 280px;
                position: relative;

                &:hover .item-body__hover {
                    display: block;
                }
                .item-body__hover {
                    display: none;
                    width: 100%;
                    position: absolute;
                    bottom: -4em;
                    left: 0;
                    background: #fff;
                    box-shadow: 0 0 25px rgba(#8eadc3, 0.35);
                    z-index: 5;
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 11px;
                        position: absolute;
                        background: #fff;
                        top: -11px;
                    }

                    img {
                        display: block;
                        margin: 0 auto 20px;
                        z-index: 8;
                    }
                }
                //&:hover {
                //  background-position: 49% 90%;
                //  height: calc(+19rem );
                //  box-sizing: border-box;
                //}
                h3 {
                    margin-top: 0;
                    font: $font-desktop-medium 'Roboto', sans-serif;
                    position: relative;
                }
                ul {
                    display: block;
                    max-width: 600px;
                    margin: 1rem 0 0;
                    list-style-position: inside;
                    padding: 0;

                    li {
                        color: #000;
                        font-family: Roboto, sans-serif;
                        font-weight: 400;
                        list-style: none;
                        margin-bottom: 8px;
                        line-height: 120%;
                        padding: 0;
                        text-align: left;
                    }
                }
                .hover-arrow {
                    margin: 0 auto;
                }
            }
        }
        h2 {
            margin-bottom: 30px;
            padding: 0;
        }
    }
    .landing__advantages {
        background: darken(#eff6fa, 2%);
        h2 {
            max-width: none;
        }
        .advantage__item {
            display: flex;
            align-items: center;
            @include layout-md {
                flex-direction: column;
                text-align: center;
            }
            .advantage-item__icon {
                display: block;
                padding: 0;
                margin: 0 20px 0 0;
                @include layout-md {
                    margin: 0 0 20px;
                }
                img {
                    width: 50px;
                }
            }
            .advantages-item__info {
                color: #000;
                font-size: 1.2rem;
                font-weight: 500;
                width: 100%;
                line-height: 150%;
            }
        }
    }
    .landing__scenario {
        .stepper {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                display: block;
                width: 80%;
                height: 3px;
                top: calc(80px + 1rem);
                left: 50%;
                transform: translateX(-50%);
                background: rgba(#2e5b8d, 0.3);
            }
        }

        .step {
            .image-container {
                display: inline-block;
                position: relative;
                width: 160px;
                height: 160px;
                &:not(.arbitrary):before {
                    content: '';
                    z-index: 1;
                    top: -10px;
                    left: -10px;
                    position: absolute;
                    display: block;
                    width: 180px;
                    height: 180px;
                    background: #fff;
                }
                &:hover {
                    .pulsing {
                        .one {
                            @keyframes pulse-sizing-first {
                                40% {
                                    opacity: 1;
                                    transform: scale(1.1);
                                }
                            }
                            animation: pulse-sizing-first 1.8s ease-in-out
                                infinite;
                        }
                        .two {
                            @keyframes pulse-sizing-second {
                                60% {
                                    opacity: 0.15;
                                    transform: scale(1.3);
                                }
                            }
                            animation: pulse-sizing-second 1.8s infinite;
                            animation-timing-function: ease-in-out;
                        }
                        .three {
                            @keyframes pulse-sizing-third {
                                60% {
                                    opacity: 0.15;
                                    transform: scale(1.4);
                                }
                            }
                            animation: pulse-sizing-third 1.8s infinite;
                            animation-timing-function: ease-in-out;
                        }
                    }
                }
                //.pulsing {
                //  position: absolute;
                //  text-align: center;
                //  top: 22px;
                //  left: 20px;
                //  cursor: help;
                //  .one {
                //    width: 18px;
                //    height: 18px;
                //    background: rgba($light-blue, .9);
                //    opacity: .7;
                //    display: block;
                //    position: absolute;
                //    border-radius: 50%;
                //  }
                //  .two {
                //    position: absolute;
                //    border-radius: 50%;
                //    display: block;
                //    width: 32px;
                //    height: 32px;
                //    background: rgba($light-blue, .25);
                //    left: -7px;
                //    top: -7px;
                //  }
                //  .three {
                //    position: absolute;
                //    border-radius: 50%;
                //    display: block;
                //    width: 44px;
                //    height: 44px;
                //    background: rgba($light-blue, .1);
                //    left: -13px;
                //    top: -13px;
                //  }
                //}
                &.arbitrary {
                    width: 110px;
                    height: 110px;
                    position: relative;
                    margin-top: 15px;
                    &:after {
                        content: '';
                        display: block;
                        height: 55px;
                        position: absolute;
                        width: 280%;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        border-bottom: 3px dashed #f0584d;
                        border-left: 3px dashed #f0584d;
                        border-right: 3px dashed #f0584d;
                        z-index: -1;
                    }
                }
                img {
                    display: block;
                    max-width: 100%;
                    position: relative;
                    z-index: 2;
                }
            }

            h4 {
                font-family: 'Gotham Pro', sans-serif;
                font-size: $font-desktop-medium;
                width: 170px;
                margin: 0 auto;
            }
        }

        h2 {
            margin-bottom: 100px;
            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .scheme-container {
            text-align: center;
            height: 100px;
            .scheme-label {
                width: 400px;
                margin: 0 auto;
                background: $gray;
                color: #343436;
                font: normal $font-desktop-medium 'Gotham Pro', sans-serif;
                line-height: 150%;
                padding: 10px;
                position: relative;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 50px;
                    width: 150%;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%);
                    border-top: 3px solid $gray;
                    border-left: 3px solid $gray;
                    border-right: 3px solid $gray;
                }
            }
            span {
                height: 5px;
                width: 70%;
                margin-top: 40px;
                display: inline-block;
                background: $blue;
                position: relative;
                &:before,
                &:after {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 30px;
                    background-color: $blue;
                    position: absolute;
                    bottom: 0;
                    border-radius: 5px;
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }
    }
}

.landing__arbitration {
    p {
        text-align: left;
        margin: 0;
        padding: 0 0 25px 0;
    }
    .ui.grid {
        flex-direction: column;
    }
    padding: 100px 0;
    min-height: 500px !important;
    //background: url(../images/arbitrary.jpeg) no-repeat center;
    //background-size: cover;
    background: #eff6fa;
    position: relative;
    z-index: 1;
    .arbitration__detailed-btn {
        padding: 4px 35px;
        position: relative;
        z-index: 1;
        background: #027ec2;
        //border: 2px solid #fff;
        display: inline-block;
        font-size: $font-desktop * 1.05;
        font-weight: 400;
        color: #fff;
        border-radius: 3px;
        text-decoration: none;
        margin: 10px auto;
        height: auto !important;
        &:hover {
            background: #027ec2;
            z-index: 1;
        }
    }
    //
    //&:before {
    //  content: '';
    //  top: 0;
    //  width: 100%;
    //  height: 100%;
    //  position: absolute;
    //  background: #f4f4f4;
    //  opacity: .95;
    //}

    .key-aspects {
        position: relative;
        padding-left: 75px;
        margin-top: 30px;

        p {
            text-align: left;
            width: 72%;
            margin-left: 30px;
        }
        &:not(:last-child) {
            padding-bottom: 30px;
        }
        &:first-of-type {
            span {
                img {
                    width: 60px;
                    height: 60px;
                    top: 2px;
                    position: relative;
                }
            }
        }
        &:last-of-type {
            span {
                img {
                    position: relative;
                    left: 2px;
                    top: -1px;
                }
            }
        }
        span {
            position: absolute;
            text-align: center;
            color: #fff;
            font-size: 25px;
            left: 0;
            top: -12px;
            //display: block;
            width: 80px;
            height: 80px;
            background: #ffb013;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 50px;
                max-width: 50px;
            }
        }
    }
}

@keyframes arrows {
    0% {
        border-left: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/-6) rotate(-45deg);
    }
    10%,
    90% {
        border-left: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
    }
    50% {
        border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
        border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
        transform: translate($size/-6, 0px) rotate(-45deg);
    }
    100% {
        border-left: $size/3 solid rgba(0, 0, 0, 0);
        border-bottom: $size/3 solid rgba(0, 0, 0, 0);
        transform: translate($size/-6, $size * 4/6) rotate(-45deg);
    }
}

//Seller block
.landing__seller {
    background: #eff6fa;
}

.landing__marketplace,
.landing__seller {
    h2 {
        @include layout-sm {
            text-align: center;
            margin: {
                left: auto;
                right: auto;
                bottom: 5rem;
            }
        }
        @include layout-md {
            margin-bottom: 7rem;
        }
    }
}
.landing__buyer {
    margin: 0;
    display: block;
    padding: 100px 0;
    overflow: hidden;
    .buyer-image {
        display: none !important;
        @include layout-md {
            display: inline-block !important;
        }
    }
}

.buyer-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    position: relative;
    margin-top: 2rem;
    @include layout-xs {
        margin-top: 3rem;
    }

    .buyer-item__icon {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #0288d1;
        margin-right: 16px;
        @include layout-sm {
            width: 80px;
            height: 80px;
            margin-right: 32px;
        }
        img {
            display: block;
            width: 30px;
            height: 30px;
            @include layout-sm {
                width: 50px;
                height: 50px;
            }
        }
    }
    .buyer-text__block {
        margin: 0;
    }
}

.landing__paymethods {
    h2 {
        max-width: none;
    }
    .payment-methods-block {
        flex-direction: row;
        justify-content: space-between;
    }
    .payment__methods-block {
        //box-shadow: 0 0 20px rgba(142, 173, 195, 0.25);
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        //width: 80%;
        height: 100%;
        padding: 25px 15px;
        background: #fff;
        border: 1px solid #c8dae4;
        border-radius: 3px;

        p {
            margin: 0;
        }
        .payment__methods-img {
            text-align: center;
            img {
                height: 80px;
                object-fit: contain;
                max-width: 80%;
                margin-bottom: 20px;
            }
        }
        .payment__methods-info {
            width: 100%;
            text-align: center;
            //margin-left: 2rem;
        }
    }
}
