@media screen and (max-width: 767px) {
    .ui.grid.container {
        width: 100% !important;
    }

    .user-layout {
        padding-top: 60px;
    }

    .entity-info {
        .container {
            .item {
                flex: 1 0 100%;
            }
        }
        .calculator {
            flex-direction: column;
            & > div {
                width: 100%;
                margin: 0 0 5px;
                &:nth-child(2) {
                    background: $gray;
                }
            }
        }
        .row {
            .sixteen.wide.column + .column {
                margin-top: 15px;
            }
        }
        .flex-wrapper.deposits {
            flex-direction: column;
            .amount {
                input,
                label {
                    text-align: left;
                }
            }
            .amount:last-child {
                margin: 0;
            }
        }
    }
    .entity-controls {
        button {
            font-size: $font-desktop-extra-small !important;
            padding: 0 8px;
            margin-bottom: 4px;
            svg {
                display: none;
            }
        }
    }

    //tabs

    ul.tabs-menu {
        padding: 0;
        li {
            &:before,
            &:after {
                display: none !important;
            }
            a {
                &:before,
                &:after {
                    display: none !important;
                }
            }
        }
    }

    //tables
    .transactions__table {
        .date {
            display: none;
        }
        th {
            width: 33.3333% !important;
        }
    }

    .history__table {
        table {
            th {
                width: 33.3333% !important;
            }
            .id,
            .date,
            .user {
                display: none;
            }
        }
    }

    //wizard page
    .status-message {
        .share-block {
            .link-wrapper {
                p.link {
                    display: none;
                }
            }
            p.link {
                width: 100px;
            }
        }
    }
    .message {
        align-items: flex-start;
        .message__icon {
            position: relative;
            top: 2px;
        }

        &__row {
            flex-direction: column;
        }
    }

    .new-contract {
        .form-buttons-group {
            display: flex;
            justify-content: space-between;
            //flex-direction: row-reverse;
        }
        .flex_vertically-centered {
            .currency {
                display: none;
            }
        }
        .checkbox-field {
            margin-top: 20px;
        }

        .deposit-option {
            margin: 0 0 35px;
        }
        .timeline-block {
            flex-direction: column;
            align-items: center;
            border-bottom: none;
            & > div {
                padding: 1.5rem;
                margin-bottom: 10px;
                width: 100%;
                font-style: $font-desktop;
                background: $gray;
                .today {
                    color: $blue;
                    font-weight: 700;
                }
                &:after {
                    display: none;
                }
            }
        }
        .buttons-row {
            flex-wrap: wrap;
            & > div {
                max-width: 100%;
                margin: 0 0 5px !important;
            }
        }

        .commission-type-block {
            & > div {
                display: flex;
                flex-direction: column;
                label.option {
                    display: block;
                    margin: 0 0 15px !important;
                }
            }
        }

        .payment-methods-grid {
            & > div {
                flex: 1 1 50%;

                img {
                    height: 20px !important;
                }
            }
        }

        .options-grid {
            &.nowrap {
                h3 {
                    display: none;
                }
            }
            .esc-old-paymethod h3 {
                color: #0288d1;
                font-weight: 500;
            }
        }
    }
    .progress-bar {
        & > div {
            &:before {
                width: 100% !important;
            }
        }
        .step-num {
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
        }
        span:not(.step-num) {
            display: none;
        }
    }

    //profile
    .profile {
        .profile-info {
            display: flex;
            flex-direction: column;
            .left,
            .right {
                width: 100%;
            }
        }
    }

    .content-header {
        margin: 15px auto;
        padding: 0 15px;
        text-align: center;
        h1 {
            font-size: 1.5rem;
        }
    }
    header.app-bar {
        padding: 0;
        .logo {
            margin-left: 10px;
        }
        .header .navigation__item {
            font-size: 0.8rem;
        }
    }

    .basement.basement_spaced-between {
        justify-content: flex-end;
        margin: 15px 0 50px;
        ul.pagination {
            margin: 0;
        }
    }
    .filter-block {
        padding: 8px 0;
        justify-content: flex-end;
        .filter-type {
            padding: 3px 8px;
            background: $gray;
            margin: 0 0 0 10px;
            border-radius: 2px;
            text-decoration: none !important;
        }
        label {
            display: none;
        }
    }

    .footer-content {
        .footer__nav {
            text-align: center;
            & > div {
                display: block !important;
                margin-right: 0;
            }
            a {
                display: inline-block;
            }
        }
    }
    .api {
        .opblock-summary {
            .opblock-summary-path {
                display: block;
                width: 80%;
            }

            .opblock-summary-description {
                display: block;
                width: 70% !important;
            }
        }
        .swagger-ui .opblock .opblock-summary {
            flex-wrap: wrap;
        }
        .swagger-ui .model-title {
            word-break: break-all;
        }
    }

    //List items

    .list-item {
        .top {
            .user_block {
                display: none;
            }
            .info-block {
                width: 100%;
                padding: 0.5rem 0.8rem;
                .duration-block {
                    margin-top: 5px;
                }
                .arrow-container {
                    display: none;
                }
            }
        }
        .bottom {
            padding: 0.5rem 0.8rem;
            .amount-block {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                label,
                span {
                    display: block;
                    white-space: nowrap;
                }
                span {
                    padding: 4px 10px;
                }
            }
        }
    }

    div.page {
        flex-direction: column;
        .page__content-wrapper {
            margin-top: 10px;
            text-align: center;
        }
    }

    .content-header.content-header_complex {
        justify-content: flex-start;
        flex-direction: column;
    }

    .controls-block-wrapper .controls-block {
        a,
        button {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .user-layout {
        //.user-layout__content-wrapper{
        //  width:100%;
        //  padding:0 1rem;
        //}

        .user-layout__sidebar {
            width: 220px;
            padding-left: 0;
            padding-right: 0;
            ul {
                li {
                    a {
                        padding: 0 10px;
                        line-height: 120%;
                        span {
                            font-size: $font-desktop-small;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .user-layout {
        .user-layout__content-wrapper {
            width: 100%;
            max-width: 100%;
            padding: 0 1rem;
        }
    }
}
