@import 'variables';

.progress-bar {
    display: flex;
    margin: 0 0 15px;

    & > div {
        padding: 25px 0;
        flex: 1 0 25%;
        position: relative;
        &:last-child {
            flex: 0 1 auto;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }
        &:not(:last-child) {
            flex-grow: 1;
            flex-shrink: 1;
            &:before {
                content: '';
                width: calc(100% - 8px);
                position: absolute;
                display: block;
                height: 2px;
                background: $border-color;
                left: 0;
                top: 50%;
                transform: translateY(-1px);
                z-index: 0;
            }
        }
        &.active {
            .step-num {
                background: $blue;
            }
        }
        &.passed {
            .step-num {
                background: $green;
            }
        }
    }
    span:not(.step-num) {
        font-size: $font-desktop-small;
        padding: 0 5px;
        background: #fff;
        position: relative;
        z-index: 1;
    }

    .step-num {
        display: inline-block;
        position: relative;
        z-index: 1;
        background: $border-color;
        width: 1.5rem;
        height: 1.5rem;
        color: #fff;
        font-size: $font-desktop-small;
        font-weight: 400;
        text-align: center;
        border-radius: 50%;
        line-height: 1.5rem;
        svg {
            position: relative;
            top: 2px;
            font-size: $font-desktop;
        }
    }
}

.new-contract {
    position: relative;

    .custom-grid {
        display: flex;
        justify-content: space-between;
        gap: 0 16px;

        @include mq(650) {
            flex-direction: column;
        }

        .form-item {
            display: flex;
            position: relative;
            align-items: flex-end;
            flex-direction: column;
            padding: 39px 10px 12px 10px;
            border-radius: 3px;

            @include mq(650) {
                width: 100%;
                min-width: 190px;
            }

            input[type='text'] {
                text-align: right;
            }
            input:disabled {
                border-bottom: none;
            }

            &.active {
                background-color: $gray;
            }
            .role-label {
                position: absolute;
                right: 0;
                top: -28px;

                @include mq(767) {
                    top: -21px;
                }
            }

            margin-bottom: 10px;

            @include mq(767) {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .message.message_info {
        min-height: 60px;
    }
    .form-item {
        label {
            font-size: $font-desktop-small;
            color: $blue;
            text-transform: uppercase;
            font-weight: 500;
            text-align: right;
        }
        span {
            white-space: nowrap;
        }
        .sum-flex-wrapper {
            display: flex;
            align-items: center;
            input {
                width: 100px;
            }

            span.currency {
                padding-bottom: 20px;
                display: inline-block;
                margin-left: 3px;
            }
            .tooltip-icon {
                margin-bottom: 20px;
            }
        }

        [data-react-toolbox='input'] {
            padding-top: 0;
            margin-right: 8px;
            &:after {
                top: 30% !important;
            }
        }
    }
    .contract-text-block__btn {
        color: $blue;
        font-size: $font-desktop-medium;
        font-weight: 500;
        cursor: default;
        &.clickable {
            cursor: pointer;
        }
    }

    .withHint.users-search {
        justify-content: flex-end;
        .users-search-block {
            width: 250px;
            flex-grow: 0;
        }
    }
    .selected-template {
        margin-bottom: 0.5rem;
    }

    .message.message_info {
        margin: 10px 0;
    }

    .contract-text-block {
        .contract-text-block__btn {
            display: flex;
            align-items: center;
        }
    }

    .row.amount-row {
        margin-top: 15px;
    }

    .initiator-block-wrapper {
        position: relative;
        .badge {
            position: absolute;
            top: -24px;
            left: 0;
        }
    }
    .flex {
        & > div:not(:last-child) {
            margin-right: 10px;
        }
        .currency-picker {
            width: 64px;
            flex-shrink: 0;
        }
    }
    .column {
        &.no-padding {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            span.badge {
                margin: 0;
                position: relative;
                top: 6px;
            }
        }
    }
    .quantitative-offer-block {
        margin-bottom: 5px;

        @include mq(767) {
            margin-bottom: 32px;
        }
    }
    .error-block {
        padding: 10px;
        margin: 10px 0;
        border: 1px solid $red;
        font-size: $font-desktop-small;
        color: $red;
        text-align: center;
        border-radius: $border-radius;
        background: #fff;
        box-shadow: 0 0 3px rgba($red, 0.2);
    }

    .offer-counts {
        line-height: 110% !important;
        margin-left: 1.5rem;
        [data-react-toolbox='input'] {
            padding: 0;
            width: 60px;
        }
        label {
            font-size: 14px !important;
        }
    }
    .wizard-tip {
        //max-width:600px;
        //margin:0 auto;
        text-align: center;
        padding: 10px;
        background: lighten($gray, 1%);
        border-radius: $border-radius;

        p,
        .list {
            font-size: $font-desktop-small;
            color: $text-color;
            margin: 0 0 5px;
            line-height: 150%;
        }
        .item {
            padding: 0;
            line-height: 150%;
            b {
                font-weight: 500;
                color: $blue;
            }
        }
    }
    .dropzone {
        padding: 1rem;
        text-align: center;
        border: 2px dashed $blue;
        background: rgba($blue, 0.05);
        border-radius: $border-radius;
        color: darken($blue, 5%);
        font-weight: 500;
        font-size: $font-desktop;
        cursor: pointer;
        margin: 10px 0;
        .dropzone__restrictions {
            margin: 5px 0 0;
            p {
                font-weight: 300;
                color: rgba($light-text-color, 0.8);
                b {
                    font-weight: 300;
                }
            }
        }
        &.dropzone_rejected {
            border-color: $red;
        }
        p {
            margin: 0;
        }
    }
    .form-buttons-group {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding-top: 1rem;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .terms {
            width: 100%;
            color: $text-color;
        }
        & > button:first-of-type {
            margin-left: 0;
        }
    }
    .payments-block {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        margin: 1rem 0 0;
        & > div {
            flex: 0 1 33.333%;
            text-align: center;
            div[data-react-toolbox='dropdown'] {
                margin-left: 10px;
                input {
                    width: 80px;
                }
                & > div {
                    &:after {
                        top: 15px !important;
                    }
                }
            }

            & > div,
            .flex > div,
            .flex > div[data-react-toolbox='dropdown'] {
                padding-top: 0 !important;
                & > div {
                    padding-top: 0 !important;
                }
            }

            &:not(:last-child) {
                margin-right: 15px;
            }
            &:nth-child(2) {
                flex-basis: 30%;
                margin-right: 25px;
            }

            input {
                width: 100px;
                text-align: center;
                display: inline-block;
            }
            background: #fff;
            position: relative;

            label {
                color: $text-color;
                font-size: $font-desktop-small;
                //text-align: center;
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    h3 {
        color: $blue;
        font-size: $font-desktop-medium;
        font-weight: 300;
        margin: 0.7rem 0 0.5rem;
    }
    .timeline-block {
        margin: 2rem 0 1rem !important;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 2px solid $blue;
        padding-bottom: 20px;

        & > div {
            text-align: center;
            line-height: 145%;
            width: 30%;
            background: #fff;
            font-size: $font-desktop-small;
            position: relative;
            &:first-of-type {
                svg {
                    margin-bottom: 4px;
                }
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 3px;
                height: 5px;
                left: 50%;
                bottom: -20px;
                transform: translateX(-50%);
                background: $blue;
            }
        }
        .location-icon {
            color: $blue;
            font-size: $font-desktop-medium;
        }
        .date-picker,
        .days-count {
            display: inline-block;
            margin-right: 4px;
            & > div {
                padding: 0 !important;
            }
        }
        .date-picker {
            & > div {
                & > div {
                    padding: 0 !important;
                }
            }
        }
        .days-count {
            width: 50px;
            input {
                text-align: center !important;
                font-size: $font-desktop-small;
            }
        }
        .date-picker {
            width: 100px !important;
            input {
                text-align: center !important;
                font-size: $font-desktop-small;
            }
        }
    }
    .line-break {
        width: 100%;
    }
    p.helper-text {
        font-size: $font-desktop-extra-small;
        color: $light-text-color;
        word-break: break-word;
        word-wrap: break-word;
    }
    .option-withHint {
        & > label {
            margin-bottom: 0;
        }
        display: flex;
        align-items: center;
        margin-bottom: 0;
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }
    .label-text {
        font-size: $font-desktop-small;
        color: $light-text-color;
        margin-right: 1rem;
        display: inline-block;
    }
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    textarea {
        line-height: 160% !important;
        font-size: $font-desktop-small;
    }
    .withHint {
        display: flex !important;
        align-items: center;
        &.checkbox-field {
            margin: 10px 0;
            & > label {
                margin: 0;
            }
        }
        & > div {
            flex-grow: 1;
        }
    }

    .content-header {
        margin-bottom: 1rem;
        h1 {
            margin: 0;
        }
    }

    .ui.grid {
        .row.no-vertical-paddings {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        //& > .column {
        //  padding-top: .6em;
        //  padding-bottom: .3em;
        //  &.no-vertical-paddings {
        //    padding-top: 0;
        //    padding-bottom: 0;
        //  }
        //}
    }

    textarea {
        resize: none;
    }
    .alert {
        margin: 10px 0;
        font-size: $font-desktop-small;
        padding: 0.8em;
    }
    .terms {
        color: $blue;
    }
    p {
        font-size: $font-desktop-small;
    }
    .toggle-visibility-btn {
        background: $gray;
        color: $light-text-color;
        font-size: $font-desktop-small;
        border-radius: $border-radius;
        padding: 0.35rem 0.6rem;
        margin: 0 0 0.5rem;
        text-align: center;
        display: block;
        cursor: pointer;
        &:hover {
            background: darken($gray, 5%);
        }
    }
    //.column.partner {
    //  position: relative;
    //  display: flex;
    //  align-items: flex-end;
    //  top: 5px;
    //  width: 100%;
    //  max-width: 250px;
    //  svg {
    //    margin-bottom: 8px;
    //  }
    //  [data-react-toolbox="input"] {
    //    padding-bottom: 0;
    //    &:after {
    //      display: none !important;
    //    }
    //  }
    //  ._1p4yC {
    //    position: absolute;
    //  }
    //}

    //.buttons-row {
    //  display: flex;
    //  align-items: center;
    //  input[type='file'] {
    //    opacity: 0;
    //    width: 0;
    //    height: 0;
    //    top: 0;
    //    position: absolute;
    //  }
    //
    //  & > div {
    //    flex: 1 1 100%;
    //    max-width: 50%;
    //    &:not(:last-child) {
    //      margin-right: 10px;
    //    }
    //    & > .add-field-button {
    //      position: relative;
    //      display: block;
    //      text-align: center;
    //    }
    //  }
    //}

    .commission-type-label {
        margin-top: 0 0 1rem;
    }

    [data-react-toolbox='radio-group'] {
        margin-right: 1.5rem;
        &.commissions-type {
            display: flex;
            margin-bottom: 15px;
        }
        .option {
            margin-right: 25px;
            margin-bottom: 0;
        }
        [data-react-toolbox='radio'] {
            width: 15px;
            height: 15px;
        }
    }
    [data-react-toolbox='checkbox'] {
        margin-bottom: 0;
    }
    .add-field-button {
        font-size: $font-desktop-small;
        display: inline-block;
        color: #fff;
        padding: 0.15rem 0.6rem;
        margin: 0 0 0.5rem;
        background: $blue;
        border-radius: 1rem;
        cursor: pointer;
        &.square {
            border-radius: $border-radius;
            padding: 0.35rem 0.6rem;
        }
        &.dark {
            background: $dark;
            &:hover {
                background: darken($dark, 2%);
            }
        }
        .tooltip-icon {
            color: #fff;
            position: relative;
            top: -1px;
        }
        &:not(:last-child) {
            margin-right: 5px;
        }
        &:hover {
            background: darken($blue, 5%);
        }
    }

    .options-grid-wrapper {
        height: 100px;
        position: relative;

        &.opened {
            .options-grid-toggle {
                padding: 15px 15px 5px 15px;
                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1),
                    0 1px 5px 0 rgba(0, 0, 0, 0.08);
                border-top: 1px solid $border-color;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -15px;
                height: auto;
                .options-grid {
                    height: 100%;
                    overflow: visible;
                }
            }
        }

        .options-grid-toggle {
            position: absolute;
            z-index: 10;
            max-width: 100%;
            min-width: 100%;
            background: #fff;
            height: 100%;
            border-bottom: 1px solid $border-color;
            transition: all 0.2s ease-in-out;

            .options-grid {
                height: 100%;
                overflow: hidden;
            }

            .toggle-trigger {
                position: absolute;
                background: $border-color;
                height: 22px;
                color: $light-text-color;
                padding: 0 4px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                right: 0;
                bottom: -22px;
                display: flex;
                align-items: center;
                &:hover {
                    background: darken($border-color, 5%);
                }
                span {
                    font-size: $font-desktop-extra-small * 0.9;
                }
                svg {
                    font-size: $font-desktop;
                }
            }
        }
    }

    .options-grid {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        &.nowrap {
            flex-wrap: nowrap;
            & > div {
                flex: 0 1 33%;
                h3 {
                    color: $blue;
                }
            }
        }
        @media screen and (max-width: 575px) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: auto;
        }
        & > div,
        &--item {
            background: #fff;
            border: 2px solid darken($gray, 2%);
            padding: 10px;
            border-radius: $border-radius;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            height: 90px;
            p.commission {
                max-width: 100%;
                font-size: $font-desktop-extra-small;
                display: flex;
                align-items: center;

                @include mq(767) {
                    margin-bottom: 10px;
                }
            }
            h3 {
                margin: 0;
                font-size: 1rem;
                font-family: 'Roboto', sans-serif;
                color: $text-color;
            }
            .category-img {
                position: absolute;
                right: 8px;
                bottom: 8px;
                display: flex;
                svg {
                    font-size: $font-desktop-extra-large;
                    color: $border-color;
                }
            }
            img {
                height: 25px;
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
            &.disabled {
                pointer-events: none;
                background: $border-color;
                color: $light-text-color;
                h3 {
                    color: $light-text-color;
                }
                img {
                    opacity: 0.5;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                }
            }
            &.active {
                border: 2px solid $blue;
                background: #fff;
                h3 {
                    color: $blue;
                }
            }
            &:not(.active):hover {
                background: $pristine-input-bg;
            }
            @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 90px;

                .category-img {
                    display: none;
                }
                svg {
                    font-size: $font-desktop-medium;
                    margin-top: 4px;
                }
                h3 {
                    font-size: $font-desktop-small;
                }

                p {
                    display: none;
                }
                .commission {
                    display: none;
                }

                img {
                    right: 8px;
                    bottom: 5px;
                }
            }
        }
    }

    .input-amount {
        min-width: 80px;
        @include mq(767) {
            min-width: 100px;
        }
    }

    .options-grid > div.esc-old-paymethod h3 {
        color: #0288d1;
        font-size: 14px;
    }
}

.esc-old-paymethod {
    user-select: none;
}

.esc-old-paymethod h3 {
    font-weight: 500;
}

.select-paymethod {
    max-width: 190px;

    @media (max-width: 767px) {
        max-width: 222px;
    }

    &--full-width {
        max-width: 100%;
    }

    &--withdraw {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;

        label {
            position: absolute;
            top: -18px;
            left: 0;
            font-size: 14px;
            line-height: 1.2;
            color: rgba(#000, 0.4);
        }
    }
}

.input-wrap-row {
    display: flex;
    align-items: flex-start;
}
.input-wrap-narrow {
    .ICLRf {
        padding: 0;
        margin-top: -7px;
    }

    &--currency {
        [data-react-toolbox='input'] {
            padding: 0;
            &:after {
                top: 35%;
            }
            [name='Currency'] {
                padding: 4px 0 8px 0;
                height: 28px;
            }
        }
    }

    &--commission-type {
        [data-react-toolbox='input'] {
            padding: 0;
            &:after {
                top: 48%;
            }
        }
    }

    &--withdraw {
        [data-react-toolbox='input'] {
            padding: 0;
            &:after {
                top: 48%;
            }
        }
    }

    &--align_right {
        input {
            text-align: right;
        }
    }
    &--size_lg {
        max-width: 250px;
    }
    &--size_md {
        max-width: 120px;
    }
    &--size_xs {
        max-width: 65px;
    }
}

.info-hr {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.85);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
}

.form-item {
    .user-pic {
        width: 24px;
        height: 24px;
    }
    &__user-panel {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
