.detailed-page {
    border: 1px solid $border-color;
    .detailed-page-header {
        display: flex;
        .detailed-page-header__right-block {
            padding: 1rem 1rem 1rem 0;
            text-align: right;
            flex-grow: 1;
            p.sum {
                font-size: 2rem;
                font-family: Roboto, Helvetica, Arial, sans-serif;
                margin-bottom: 8px;
                line-height: 100%;
            }
        }
        .detailed-page-header__left-block {
            width: 70%;
            padding: 1rem 0 1rem 1rem;
            p {
                margin: 0;
            }
            h1 {
                white-space: nowrap;
                color: $text-color;
                font-weight: 300;
                font-family: Roboto, Helvetica, Arial, sans-serif;
                text-transform: capitalize;
                margin-bottom: 8px;
                font-size: 2rem;
                line-height: 100%;
            }
        }
    }
    .detailed-page-body {
        padding: 1rem;
    }
}
