table.table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10px;
    &.transactions__table {
        margin-top: 10px;
    }

    .aligned-right {
        text-align: right;
    }
    .aligned-left {
        text-align: left;
    }

    .small {
        font-size: $font-desktop-extra-small;
    }
    .centered {
        text-align: center;
    }

    thead {
        tr {
            background: darken($gray, 2%);
        }
        th {
            font-size: $font-desktop-extra-small;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-weight: 500;
            color: $text-color;
            text-align: center;
            line-height: 130%;
            padding: 10px 5px;
            &.id {
                width: 60px;
            }
            &.date {
                width: 85px;
            }
        }
    }
    tbody {
        tr {
            cursor: pointer;
        }
        td {
            padding: 10px;
            font-size: $font-desktop-extra-small;
            line-height: 130%;
            .badge {
                font-size: $font-desktop-extra-small !important;
            }
            &.name {
                a {
                    display: inline-block;
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.amount {
                font-weight: 500;
                span {
                    &:first-of-type {
                        display: block;
                    }
                }
            }
            &.user {
                .user-profile-link {
                    .badge {
                        display: none;
                    }
                }
            }
        }
    }
    &.striped {
        tbody {
            tr:nth-child(2n) {
                background: rgba($gray, 0.8);
            }
        }
    }
    &.fixed {
        table-layout: fixed;
    }
}

.history-table {
    .history-table__item {
        display: flex;
        padding: 10px 5px;
        width: 100%;
        &:nth-child(2n) {
            background: rgba($gray, 0.6);
        }
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        .history-table__item-id {
            width: 15%;
            font-size: $font-desktop-extra-small;
            color: $light-text-color;
        }
        .history-table__item-info {
            width: 85%;
            flex-grow: 1;
            .name {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                span:first-child {
                    font-weight: 500;
                    color: $text-color;
                    font-size: $font-desktop-small;
                    margin-right: 5px;
                    text-transform: capitalize;
                    line-height: 130%;
                }
                span:last-child {
                    color: $text-color;
                    font-size: $font-desktop-extra-small;
                }
            }
            .sum {
                font-size: $font-desktop-small;
                color: $blue;
                font-weight: 400;
            }
        }
    }
}
