@media screen and (max-width: 480px) {
    .landing {
        .landing__benefit {
            .benefit-item__content {
                ul li {
                    margin-bottom: 20px;
                }
            }
        }
        .landing__promo {
            .promo-block {
                margin-top: 1em;

                .promo-block__item {
                    align-items: center;
                    height: 108px;

                    .promo-value {
                        font-size: 1.8em !important;
                    }
                    .promo_text {
                        font-size: 1.2em !important;
                    }
                    p {
                        width: 100%;
                    }
                }
            }
        }

        .arrow-down {
            display: none;
        }

        p.buyer-item_info {
            text-align: left !important;
        }
        .api {
            .ui.grid > .column:not(.row) {
                width: 100% !important;
                display: block;
            }
            .swagger-ui {
                table {
                    table-layout: fixed;
                }
                .wrapper {
                    padding: 0 10px;
                }
                .opblock {
                    .opblock-summary {
                        flex-wrap: wrap;

                        .opblock-summary-description {
                            display: block;
                            width: 80%;
                            margin-top: 25px;
                        }
                        .opblock-summary-path {
                            width: 70%;
                        }
                    }

                    .opblock-section-header {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 20px;
                        .btn {
                            margin: 15px 0;
                        }
                    }
                }
            }
            .swagger-ui .opblock-body select {
                min-width: 80% !important;
                width: 104% !important;
            }
            .swagger-ui .model-title {
                word-break: break-all;
                line-height: 1;
            }
            .swagger-ui table.model tbody tr td:first-of-type {
                width: 40%;
                padding: 0;
            }
        }

        .landing
            .landing__benefit
            .benefit-bottom
            .benefit-block
            .benefit-item__content,
        .landing
            .landing__benefit
            .benefit-bottom
            .benefit-block
            .benefit-item__content
            ul
            li {
            margin-bottom: 1em;
        }
        .del-line {
            display: none;
        }
        .marketplace-item .marketplace-item__info {
            //background: linear-gradient(45deg, #edf2f5, #fff);
        }

        .landing__marketplace .marketplace-item .marketplace-item__icon-block {
            top: 0em !important;
        }
    }

    .landing .landing__marketplace .ui.stackable.grid > .column:not(.row) {
        margin-bottom: 0 !important;
    }

    .landing
        .landing__benefit
        .benefit-bottom
        .benefit-item__bottom-header
        .header-icon {
        display: none !important;
    }

    .landing .landing__benefit .benefit-bottom .benefit-item__bottom-header h3 {
        margin: 7px auto;
        text-align: center;
        font-size: 1.2em;
        max-width: 80%;
    }
}

//mobile
@media screen and (max-width: 768px) {
    .landing {
        & > div {
            padding: 50px 0;
        }
        .landing__advantages {
            padding: 4em 0;

            .ui[class*='three column'].doubling:not(.stackable).grid
                > .column:not(.row) {
                width: 100% !important;
            }

            .advantages-item__info {
                width: 80%;
            }

            .row.text-center {
                display: block;
            }
            h2 {
                margin: 0 0 25px;
                padding-bottom: 15px;
                text-align: center;
                &:after {
                    left: calc(50% - 75px);
                }
            }
            .item {
                text-align: left;
                margin: 0 0 15px 35px;
                padding: 25px 25px 25px 50px;

                h3 {
                    margin: 0 0 15px;
                }
                figure {
                    width: 80px;
                    height: 80px;
                    top: calc(50% - 40px);
                    left: -40px;
                }
            }
        }
        .landing__arbitration {
            padding: 4rem 0;

            .arbitration__detailed-btn {
                display: block;
                width: 80%;
                margin: 25px auto 0;
                text-align: center;
                &:hover {
                    background: #0288d1;
                    z-index: 1;
                }
            }
            h2 {
                margin-bottom: 0;
            }
            .description {
                width: 100%;
                margin: 4rem auto 1rem;
            }
            p {
                font-size: 1rem;
                width: 100%;
                margin: 0 auto;
            }
        }
        .landing__promo {
            background-size: cover;
            background-position: center;
            button {
                width: 200px !important;
            }
            .payment-methods {
                padding: 0;

                .blocks {
                    width: 100%;
                    & > .block {
                        width: 25%;
                    }
                }
            }
            .arrows {
                display: none;
            }
        }
        .landing__application {
            .item {
                text-align: center;
                display: flex;
                flex-direction: column;
                height: auto;
                h3 {
                    font-size: $font-desktop-medium;
                    text-transform: uppercase;
                    font-weight: 500;
                }
                .item__body {
                    margin: 0;
                    width: 100%;
                    padding: 25px 15px;
                    &:hover .item-body__hover {
                        display: none;
                    }

                    ul li {
                        color: $text-color;
                        margin-bottom: 15px;
                        list-style: none;
                    }
                }
            }
            figure {
                display: none !important;
            }
            h2 {
                padding-bottom: 15px;
                margin-bottom: 35px;
            }
        }
        .landing__scenario {
            padding: 35px 0;
            .col + .col:before {
                content: '';
                display: block;
                position: absolute;
                height: 25px;
                width: 4px;
                background: $blue;
                top: -20px;
                left: calc(50% - 2px);
                border-radius: 2px;
            }
            .col:after {
                display: none !important;
            }

            h2 {
                margin-bottom: 35px;
            }
            .step {
                margin-bottom: 35px;
            }
        }
        .landing__buyer {
            .buyer-wrapper {
                margin-bottom: 50px;
            }
            .buyer__bg {
                top: 74px;
            }
            .buyer-item {
                height: auto;
                p {
                    text-align: left;
                }
            }
        }
        .landing__marketplace,
        .landing__paymethods {
            .marketplace-item__block {
                margin-top: 15px !important;
                margin-bottom: 0 !important;
            }

            .marketplace-item {
                height: 200px;
                button {
                    margin-bottom: 30px;
                }
            }
        }

        .landing__marketplace {
            .ui.stackable.grid > .column:not(.row) {
                height: auto;
                //margin-bottom: 3em!important;
            }

            .icon-border {
                display: none;
            }
        }

        .seller-item_arrow {
            display: none;
        }

        .landing__paymethods {
            .payment__methods-block {
                width: 100%;
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-top: 0;
                h2 {
                    &:after {
                        left: 24%;
                    }
                }

                .payment__methods-info {
                    p:not(:last-of-type) {
                        margin-bottom: 10px;
                    }
                }
                .payment__methods-img {
                    margin-bottom: 10px;
                    img {
                        max-width: 150px;
                    }
                }
                & > div {
                    width: 100%;
                }
            }
        }
    }
    .landing__arbitration {
        .key-aspects-block {
            width: 100%;
            .key-aspects {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0 auto;
                padding: 0;
                span {
                    position: inherit;
                    margin: 25px auto 10px;
                }
                p {
                    width: 100%;
                    padding: 0;
                    text-align: center;
                }
            }
        }
    }

    .item-body__hover {
        display: none;
    }

    .landing .landing__benefit .benefit-item .benefit-item__content ul li {
        margin-bottom: 40px;
    }

    .landing .landing__benefit {
        .benefit-item__bottom-header {
            width: 90% !important;
        }

        .benefit-top .benefit-item__content {
            width: 100% !important;
        }
    }

    .landing__promo {
        .promo-block {
            width: 100% !important;
            flex-wrap: wrap;
            div.promo-response {
                width: 50% !important;
            }
            .promo-block__item {
                align-items: flex-start;

                p {
                    width: 75%;
                    text-align: center;
                }
                p.promo-value {
                    font-size: 2em !important;
                    &:before {
                        display: none !important;
                    }
                }
            }
        }
    }

    .landing__description {
        display: flex;
        flex-direction: column;
        box-shadow: none;
        div.description-text {
            display: block;
            width: 95% !important;
            margin: 0 auto;
            z-index: 6;
            p,
            ul {
                width: 100%;
            }
        }
        div.description-image {
            display: none !important;
        }
    }

    .landing__benefit {
        p {
            max-width: 70% !important;
        }

        .benefit-item__content::after,
        .benefit-item__bottom-header::before {
            display: none !important;
        }
    }

    .seller-item__icon-block .seller-item__icon,
    .marketplace-item__icon-block .marketplace-item__icon {
        top: 10%;
    }

    .del-line,
    .del-line-bottom {
        display: none !important;
    }
}

//tablet

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .landing {
        .landing__promo {
            background-position-y: -10px;
        }

        .landing__promo div.promo-response .column {
            width: 25% !important;
        }

        .landing__promo .promo-block {
            width: 85%;
            margin-top: 1em !important;
        }
        p.payment-methods {
            text-align: left;
        }
        .landing__description {
            .description-image {
                display: none;
            }

            .description-text {
                width: 95%;
                margin: 0 auto;
            }
        }
        .benefit-item__content ul li {
            margin-bottom: 30px;
        }

        .benefit-item__bottom-header h3 {
            font-size: 1em;
            text-align: center !important;
            margin: 10px auto !important;
        }
        .landing__benefit
            .benefit-bottom
            .benefit-block
            .benefit-item__bottom-header
            .header-icon {
            display: none;
        }

        .landing__marketplace .marketplace-item__block {
            .marketplace-item {
                .icon-border {
                    display: none;
                }
            }
            .seller-item_arrow {
                display: none;
            }
        }
        .landing__application .item .item__body {
            &:hover .item-body__hover {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .landing {
        .landing__promo {
            background-position-y: -32px;
        }

        .benefit-bottom .benefit-block .benefit-item__bottom-header {
            h3 {
                margin-left: 5em;
                font-size: 1em;
            }
        }
    }
}

//large

@media screen and (min-width: 1024px) {
    .container.detailed,
    .container.contracts,
    .container.deals {
        padding: 2rem 0;
        & > .col {
            padding: 0;
        }
    }
    .landing {
        .landing__promo {
            background-position: center;
            .payment-methods {
                display: flex;
                bottom: 0;
                padding: 0;
                justify-content: center;
                margin: 0 auto;
                .blocks {
                    opacity: 0.8;
                }
                p {
                    position: relative;
                    z-index: 1;
                }
            }
        }
    }
}
