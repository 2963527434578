div.sc-chat-window {
    height: 250px;
    .sc-header {
        background: $orange;
        min-height: auto;
        img {
            display: none;
        }
    }
}
div.sc-launcher {
    background: $orange;
}
.sc-user-input--emoji-icon-wrapper {
    display: none !important;
}

div.sc-message--text {
    padding: 10px 14px;
}

div.sc-message--content.sent .sc-message--text {
    background: $orange !important;
    max-width: calc(100% - 50px);
}

div.sc-header--team-name:hover,
div.sc-header--close-button:hover {
    background: darken($orange, 5%) !important;
}
