@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i&subset=cyrillic-ext');
@import 'reset';
@import 'animate';
@import 'fonts';
@import 'variables';
@import 'semantic';
@import 'mixins';
@import 'forms';
@import 'contract';
@import 'header-menu';
@import 'tables';
@import 'flexbox';
@import 'footer';
@import 'landing';
@import 'entity_info';
@import 'dialogs';
@import 'faq';
@import 'about';
@import 'arbitration';
//@import 'react-table';
@import 'loaders';
@import 'pagination';
@import 'burger_menu';
@import 'profile';
@import 'apipage';
@import 'custom_chat_styles';
@import 'transitions';
@import 'chat';
@import 'filter_widget';
@import 'social-icons-block';
@import 'wizard';
@import 'support-widget';
@import 'detailed-page';
@import 'atoms';
@import 'modal';
@import 'form';
@import 'payment-method';
@import 'common';
@import 'UserWizard';

html,
body {
    font-size: 1rem !important;
}

.form-buttons-group {
    button {
        margin: 0 4px 4px 0;
    }
    .terms {
        font-family: 'Roboto', Vardana, Tahoma, sans-serif;
        font-weight: 300;
        font-size: $font-desktop-small !important;
        margin-bottom: 10px;
        line-height: 130%;
        color: $light-text-color;
    }
}

.custom-btn {
    display: inline-block;

    border-radius: $border-radius;
    cursor: pointer;
    &.custom-btn_blue {
        background: $blue;
        color: #fff;
    }
    &.custom-btn_gray {
        background: $gray;
        color: $light-text-color;
    }
    &.custom-btn_default {
        height: 26px;
        font-size: $font-desktop-small;
        line-height: 26px;
        padding: 0 10px;
    }
    &.custom-btn_small {
        font-size: $font-desktop-extra-small;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
    }
}

h2,
h3 {
    font-weight: 400;
}

.__react_component_tooltip {
    max-width: 250px !important;
    font-weight: 300 !important;
}

.service-commission {
    .__react_component_tooltip {
        width: 200px;
    }
}

html,
body,
.app,
#root {
    height: 100%;
}

h4 {
    font-size: $font-desktop-medium;
    color: $blue;
    font-weight: 300;
}

h5 {
    font-size: $font-desktop !important;
    font-weight: 300 !important;
}

body {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
}

.container {
    &.detailed {
        padding: 0;
    }
    &.main {
        padding: 0;
        .row {
            &.content-block {
                margin-bottom: 45px;
            }
        }
    }
}

.controls-panel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.8rem;
}

.pager-state {
    font-size: $font-desktop-extra-small;
    color: $light-text-color;
    flex-shrink: 0;
    margin-left: 10px;
}

.go-back-button {
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 10px;
    vertical-align: middle;
    border: 1px solid $border-color;
    background: none;
    font-size: $font-desktop-small;
    cursor: pointer;
    outline: none;
    border-radius: $border-radius;
    color: $text-color;
}

.refresh-button {
    display: inline-block;
    height: 26px;
    align-items: center;
    padding: 0 10px;
    line-height: 24px;
    border: 1px solid $border-color;
    background: none;
    font-size: $font-desktop-extra-small;
    cursor: pointer;
    outline: none;
    border-radius: $border-radius;
    color: $text-color;
    margin-left: 10px;
    //.refresh__icon{
    //  display: inline-block;
    //  height: 100%;
    //  vertical-align: middle;
    //  line-height: 36px;
    //  font-size: $font-desktop;
    //  margin-left: 5px;
    //}
    &:hover {
        border-color: $light-blue;
        .refresh__icon {
            color: $blue;
        }
    }
}

.content-text {
    font-size: $font-desktop;
}

.tabs-content {
    background: #fff;
    padding: 15px;
    min-height: 200px;
    border-radius: $border-radius;
    @extend %shadow;
}

.tabs-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 0 0 25px;
    li {
        position: relative;
        z-index: 0;
        //&:before, &:after {
        //  content: '';
        //  z-index: 2;
        //  display: block;
        //  width: 20px;
        //  height: 20px;
        //  bottom: 0;
        //  border-radius: 50%;
        //  position: absolute;
        //}
        //&:before {
        //  left: -20px;
        //  background: darken($gray, 5%);
        //}
        //&:after {
        //  right: -20px;
        //  background: darken($gray, 5%);
        //}
        //
        //&:first-child {
        //  a:before{
        //    display: none !important;
        //  }
        //  &:before {
        //    background:lighten($gray, 2%);
        //    display: none;
        //  }
        //}
        //
        //&:last-child {
        //  a:after{
        //    display: none !important;
        //  }
        //
        //  &:after {
        //    background:lighten($gray, 2%);
        //    display: none;
        //
        //  }
        //}
        &.active {
            z-index: 1;
            overflow: visible;

            a {
                background: $blue;
                box-shadow: 0 -2px 5px -1px darken($gray, 4%);
                color: #fff;
                font-weight: 500;
                //&:before,
                //&:after {
                //  background: $blue;
                //}
            }
        }
        a {
            font-size: $font-desktop;
            line-height: 125%;
            display: block;
            padding: 10px 25px;
            color: $text-color;
            background: darken($gray, 5%);
            border: {
                top-left-radius: 15px;
                top-right-radius: 15px;
            }
            position: relative;
            //&:before,
            //&:after {
            //  content: '';
            //  z-index: 1;
            //  display: block;
            //  position: absolute;
            //  width: 10px;
            //  height: 10px;
            //  background: darken($gray, 5%);
            //  bottom: 0;
            //}
            //&:before {
            //  left: -10px;
            //}
            //&:after {
            //  right: -10px;
            //}
        }
    }
}

.basement {
    &.basement_spaced-between {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .pagination {
            margin: 10px 0;
        }
    }
}

.link {
    color: $light-text-color;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    &.link_login {
        font-size: $font-desktop-small;
        position: relative;
        top: -5px;
        color: $blue;
        &:hover {
            color: darken($blue, 10%);
        }
    }
    &:hover {
        color: $text-color;
    }
}

b.senderName {
    font-weight: 700;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    //& > footer {
    //  display: flex;
    //  width: 100%;
    //  flex: 0 1 0;
    //}
    & > main {
        display: flex;
        flex: 1 0 auto;
        & > .page {
            border: none;
            box-shadow: none;
            margin: 0 auto;
        }

        .content-header {
            color: $blue;
            margin-bottom: 1rem;
            align-items: center;
            .content-header__navigation {
                a {
                    margin-right: 10px;
                    font-size: $font-desktop-small;
                    &.primary {
                        color: $blue;
                        &:hover {
                            color: darken($blue, 5%);
                        }
                    }
                    &.secondary-link {
                        color: lighten($light-text-color, 10%);
                        &:hover {
                            color: $light-text-color;
                        }
                    }
                }
            }
            h1,
            h2 {
                margin: 0;
                font-size: $font-desktop-extra-large;
                color: $blue;
                font-weight: 300;
            }

            &.centered {
                justify-content: center;
                h1 {
                    text-align: center;
                }
            }

            &.content-header_complex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .filter {
                    font-size: $font-desktop !important;
                }
            }
        }

        & > .ui.container {
            padding: {
                top: 5rem;
                bottom: 5rem;
            }
        }
    }
}

.breadcrumbs {
    margin: {
        top: 2rem;
        bottom: 1rem;
    }
    a {
        color: $blue;

        &:hover {
            text-decoration: underline !important;
        }
    }
    .divider {
        display: inline-block;
        margin: 0 3px;
        color: $blue;
    }
    .current {
        color: $light-text-color;
        cursor: default;
    }
}

.tooltip {
    max-width: 250px;
    min-width: 200px;
    font-family: Roboto, sans-serif !important;
    font-size: $font-desktop-extra-small !important;
    line-height: 140%;
    font-weight: 300 !important;
    text-align: center;
    padding: 10px 15px !important;
}

.tooltip-block {
    border: 1px solid $border-color;
    color: $light-text-color;
    font-size: $font-desktop-small;
    padding: 10px;
    b {
        color: $blue;
    }
}

.form-buttons-group {
    padding-top: 10px;
    button:not(:first-of-type) {
        margin-left: 6px;
        @include mq(767) {
            margin-left: 0;
        }
    }
}

.esc-form__btn {
    @include mq(767) {
        margin-left: auto;

        &--font_sm {
            font-size: 11px;
        }
    }
}

.navbar-brand {
    font-weight: 500;
    color: #fff !important;
}

.tooltip-text {
    max-width: 300px;
}

.navbar {
    display: flex;
    .navbar-nav {
        display: flex;
        justify-content: center;
    }
    &.navbar-default {
        text-align: center;
        background: #fff;
        border-top: none;
    }
}

.edit {
    color: $light-text-color;
    font-size: $font-desktop;
    margin-left: 4px;
    &:hover {
        color: $text-color;
    }
}

.light-text {
    color: $light-text-color;
}

.tooltip-icon {
    font-size: 14px !important;
    color: #ccc;
    cursor: pointer;
    &:hover {
        color: $green;
    }
}

.rdtPicker {
    font-size: $font-desktop;
}
//
//input[type='text']:disabled {
//  border-bottom: none !important;
//}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 0 30px white inset;
    background-image: none;
    color: rgb(0, 0, 0);
}

.selected-template {
    display: flex;
    padding-bottom: 4px;
    justify-content: space-between;
    font-size: $font-desktop-small;
    border-bottom: 1px dotted $border-color;

    //margin-bottom: 15px;
    .buttons {
        display: flex;
        & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
        }
    }
    .selected-template__name {
        cursor: pointer;
        color: $text-color;
        font-weight: 500;

        .selected-template__icon {
            color: $green;
            margin-right: 4px;
            position: relative;
            top: 1px;
        }
    }
    .clear,
    .edit {
        background: $gray;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        color: darken($gray, 20%);
        font-size: $font-desktop;
        cursor: pointer;
    }
    .clear {
        &:hover {
            svg {
                color: $blue;
            }
        }
    }

    .edit {
        &:hover {
            svg {
                color: $green;
            }
        }
    }
}

.share-block {
    background: lighten($gray, 2%);
    border-radius: $border-radius;
    padding: 1rem;
    margin-top: 2rem;
    & > p {
        margin-bottom: 10px !important;
    }

    .link-wrapper {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
        .link-icon {
            display: flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            background: darken($gray, 15%);
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
            &.vk {
                background: #5e81a8;
            }
            svg {
                color: #fff;
            }
        }
        .share-link {
            font-size: $font-desktop;
        }
        .link {
            background: #fff;
            flex-shrink: 1;
            padding: 0 10px;
            height: 35px;
            display: flex;
            align-items: center;
            box-shadow: inset 0 0 0 1px $border-color;
            border-radius: $border-radius;
            margin: 0 10px 0 0;
            cursor: auto;
            width: 100%;
            max-width: 350px;
            overflow-x: auto;
        }

        .copy-link {
            height: 33px;
            box-sizing: border-box;
            background: $blue;
            color: #fff;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: $font-desktop-small;
            border-radius: $border-radius;
            padding: 0 10px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: darken($blue, 5%);
            }
        }
    }
}

.toggle-link {
    display: inline-block;
    color: $light-text-color;
    text-decoration: underline;
    font-size: $font-desktop-small;
    cursor: pointer;
    &:not(.blue):hover {
        color: $text-color;
    }

    &.blue {
        color: $blue;
        font-size: $font-desktop;
        text-decoration: none;
    }
}
.share-link {
    font-size: $font-desktop-small;
    color: $light-text-color;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    display: inline-block;
    font-weight: 500;
    padding: 12px;
    background: $gray;
    border-radius: $border-radius;
    transition: all 0.2s ease-in-out;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include mq(767) {
        padding: 7px 12px;
    }

    span {
        display: inline-block;
        background: darken($gray, 8%);
        width: 20px;
        height: 20px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        font-size: 10px;
        margin-right: 5px;
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        background: darken($gray, 3%);
        color: $light-text-color;

        span {
            background: darken($gray, 13%);
        }
    }
}

.select-option {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: $text-color;
    i {
        display: inline-block;
        margin-left: 4px;
        font-size: $font-desktop-extra-small;
        font-style: normal;
        font-weight: 300 !important;
        font-family: 'Roboto', Vardana, Tahoma, sans-serif;
        color: lighten($light-text-color, 3%);
        background: $gray;
        padding: 2px 5px;
        line-height: 100%;
    }
    img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        display: inline-block;
    }
}

.update-contract-block {
    .wizard-tip {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    input[disabled] {
        border: none;
    }
    .flex.flex_vertically-centered {
        & > div {
            flex-grow: 1;
        }
    }
    .currency-badge {
        color: lighten($light-text-color, 30%);
    }

    .text-block {
        h3 {
            font-size: $font-desktop;
            color: $blue;
            margin-bottom: 3px;
        }
        p {
            font-size: $font-desktop-small;
            color: $light-text-color;
        }
        .contract-agreement {
            border-bottom: 1px dotted #e2e2e2;
            padding-bottom: 4px;
            display: flex;
            justify-content: space-between;
            span {
                cursor: pointer;
            }

            .delete-terms-icon {
                svg {
                    color: darken($gray, 20%);
                    font-size: $font-desktop;
                }
                &:hover {
                    svg {
                        color: $blue;
                    }
                }
            }
            svg {
                color: $green;
                margin-right: 3px;
            }
        }
    }

    .textarea-block {
        padding-top: 0 !important;
        margin-top: 0 !important;
        padding-bottom: 0;
        .terms-block {
            margin-bottom: 5px;
        }
    }
    .calculator {
        margin-bottom: 15px;
        & > div {
            border-color: $border-color;
        }
    }
    .wizard-tip {
        padding: 10px;
        background: $gray;
        font-size: $font-desktop-small;
        color: $text-color;
    }
    .row {
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        .column {
            & > h4 {
                margin: 0 0 5px;
            }
            flex-grow: 1;
        }
    }
    h2 {
        text-align: center;
        font-size: $font-desktop-medium;
        font-weight: 300;
        color: $text-color;
        text-transform: capitalize;
    }
    h4 {
        font-size: $font-desktop;
        color: $text-color;
    }
    .flex-wrapper {
        align-items: center;
        display: flex;
    }
}

div.status-message {
    text-align: center;
    max-width: 550px;
    padding: 1rem 1.5rem;
    margin: 35px auto;
    border: 1px solid $border-color;
    box-shadow: 0 2px 2px 0 rgba(#000, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.1);
    .ui.message {
        box-shadow: none;
        border: none;
    }

    a {
        cursor: pointer;
    }

    h1 {
        margin: 0 15px 0.6rem;
        font-weight: 300;
        line-height: 125%;
        max-width: 360px;
        font-size: $font-desktop-large;
    }

    .status-message__text {
        .new-deal-name {
            font-weight: 300;
            cursor: pointer;
            font-size: 16px;
        }
        p {
            font-size: $font-desktop;
            margin: 0 0 5px;
            line-height: 150%;
            color: $light-text-color;
        }
        b {
            font-weight: 500;
            color: $text-color;
        }
    }

    p.attention {
        font-size: $font-desktop-small;
        padding: 10px;
        background: $gray;

        svg {
            position: relative;
            top: -1px;
            font-size: $font-desktop;
            margin-right: 3px;
            color: $blue;
        }
    }
    p.warning {
        border-radius: $border-radius;
        padding: 10px;
        background: rgba($red, 0.7);
        color: #fff;
        font-size: $font-desktop-small;
        svg {
            position: relative;
            top: -1px;
            font-size: $font-desktop;
            margin-right: 3px;
        }
    }
    &.failure {
        h1 {
            color: $red;
            display: inline-block;
        }
        .rhombus-icon {
            &:before {
                background: $red;
            }
            svg {
                color: #fff;
            }
        }
    }
    &.success {
        h1 {
            color: $blue;
            display: inline-block;
        }

        .rhombus-icon {
            &:before {
                background: $blue;
            }
            svg {
                color: #fff;
            }
        }
    }
}

.filter-block {
    display: flex;
    align-items: center;
    margin: 1rem 0 1.2rem;
    font-size: $font-desktop-small;

    .filter-block__item {
        margin-right: 1rem;

        display: flex;
        align-items: center;
        & > label:not(.filter-badge) {
            margin-right: 5px;
        }
        .filter-badge {
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            padding: 0.2em 0.5em;
            height: 30px;
            display: flex;
            align-items: center;
            background: $blue;
            button {
                width: auto !important;
            }
            .material-icons {
                font-size: inherit;
                color: #fff;
                margin-left: 5px;
            }
        }
    }
}

.helper-link {
    color: $blue;
    display: inline-block;
    margin-left: 2px;
    font-size: $font-desktop-extra-small;
    cursor: pointer;
}

.helper-block {
    cursor: default;
    opacity: 0;
    height: 0;
    padding: 2px 5px;
    border-radius: $border-radius;
    background: $gray;
    font-size: $font-desktop-small * 0.9;
}

.text-center {
    text-align: center !important;
}

.initiator-badge {
    color: #fff;
    display: flex;
    border-radius: 50%;
    background: $green;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
}

.transaction-table {
    table-layout: fixed;
    thead {
        th {
            &.date {
                width: 100px;
            }
            &.username {
                width: 200px;
            }
            &:not(.disabled) {
                cursor: pointer;
            }
            &.active {
                border-bottom: 3px solid $dark !important;
            }
        }
    }
    tbody > * {
        font-size: $font-desktop-small * 0.95;
    }

    b {
        font-weight: 500;
    }
    td.username {
        a {
            line-height: 120%;
            display: inline-block;
            cursor: default;
            color: $blue;
        }
    }
    td.date {
        line-height: 140%;
        color: $light-text-color;
    }
    td.name {
        color: $text-color;
        font-weight: 500;
    }
    th:first-child {
        width: 60px;
    }
}

div.commission-rates {
    margin: 0 0 10px;
    //padding: 10px;
    //border: 1px solid $border-color;
    //background: lighten($gray, 3%);

    & > p {
        font-size: $font-desktop-small;
        line-height: 150%;
        font-weight: 300;
        color: $text-color;
        margin-bottom: 2px !important;

        b {
            color: $blue;
            font-weight: 500;
        }
    }
}

span.importance-label {
    display: inline-block;
    background: $blue;
    padding: 3px 6px;
    border-radius: $border-radius;
    color: #fff;
    font-size: $font-desktop;
    font-weight: 500;
}

.editor-wrapper {
    border: 1px solid $border-color;
    .editor-toolbar {
        margin-bottom: 0 !important;
    }
    .editor-textarea {
        padding: 0 10px;
        height: 300px;
        line-height: 120%;
        font-size: $font-desktop-small;
    }
}

.counter-offer {
    .currency {
        font-size: $font-desktop;
        color: $light-text-color;
        margin-left: 10px;
    }
    .column.column_small {
        max-width: 25%;
    }
    [data-react-toolbox='date-picker'] {
        label {
            white-space: nowrap;
        }
    }
    .editor-wrapper {
        margin-bottom: 10px;
    }
    p {
        font-size: $font-desktop-small;
        color: $text-color;

        b {
            font-weight: 500;
            color: $blue;
        }
    }

    .editor-textarea {
        padding: 0 10px;
        height: 200px;
        line-height: 120%;
        font-size: $font-desktop-small;
    }
}

b.bold {
    font-weight: 700 !important;
}

.calculator-header {
    background: #f1f1f1;
    color: $text-color;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
}

.calculator {
    display: flex;
    width: 100%;
    p.commission-amount {
        font-size: $font-desktop-extra-small;
        color: lighten($light-text-color, 5%);
        margin-top: 5px;
    }
    & > div {
        flex: 0 0 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0.8rem 0.5rem;
        text-align: center;
        border: 2px solid $border-color;
        cursor: default;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &.service-commission {
            b {
                //font-size: $font-desktop-small;
                color: $text-color;
            }
        }

        h4 {
            text-align: center;
            color: $blue;
            display: block;
            margin-bottom: 5px;
            font-size: $font-desktop;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
        }
        p {
            margin-bottom: 0;
            text-align: center;
            color: $text-color;
            font-size: $font-desktop-small;
        }
        span {
            color: $light-text-color;
            font-size: $font-desktop-extra-small;
            font-weight: 300;
            margin-right: 4px;
        }
        i {
            font-style: normal;
        }
        b {
            color: $text-color;
            font-weight: 500;
            font-size: 1.25rem;
            i {
                font-size: $font-desktop-extra-small;
                font-weight: 500;
                font-style: normal;
                display: inline-block;
                margin-left: 2px;
            }
        }
    }
}

.content-header {
    &.centered {
        h1,
        h2,
        h3 {
            text-align: center;
        }
    }
    h1,
    h2,
    h3 {
        color: $blue;
        font-size: $h1-size;
        font-weight: 300;
    }
}

a.button {
    padding: 8px 10px;
    border-radius: $border-radius;
    display: inline-block;
    &.button_red {
        background: $red;
        color: #fff;
        cursor: pointer;
    }
}

.passport-required-block {
    font-family: 'Roboto', sans-serif;
    font-size: $font-desktop-small;
    h1.notification-header {
        font-size: $font-desktop;
        font-weight: 500;
    }
    a {
        margin-top: 10px;
    }
    b {
        font-weight: 500;
    }
}

.info-text-block {
    background: $gray;
    padding: 10px;
    border-radius: $border-radius;
    font-size: $font-desktop-extra-small;
    color: $light-text-color;
    line-height: 130%;
}

.login-widget {
    .content-header {
        margin-bottom: 25px;
    }
}

.login-page {
    .login-widget {
        .close-dialog {
            display: none;
        }
    }
}

div.page {
    padding: 35px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    background: #fff;
    @extend %shadow;
    .page__image-wrapper {
        padding-right: 15px;
        img {
            width: 300px;
            max-width: 100%;
        }
    }
    .page__content-wrapper {
        text-align: left;
    }

    &.page_empty {
        //background: $light-spanish-blue;
        .page__image-wrapper {
            height: 180px;
            width: 180px;
            margin-right: 25px;
        }
        border: 1px solid $border-color;
        img {
            display: block;
            height: 100%;
            max-width: 100%;
        }

        p {
            max-width: 450px;
            margin: 0 0 15px;
            font-size: $font-desktop;
            color: $text-color;
        }
    }

    &.page_500 {
        max-width: 600px;
        width: 50vw;
        margin: 25px auto;
    }

    .button {
        &.button_red {
            background: $red;
            color: #fff;
            cursor: pointer;
        }
    }
    h5 {
        font-size: $font-desktop-large !important;
        font-weight: 500 !important;
        margin: 0 0 10px;
        color: $blue;
    }
    p {
        font-size: $font-desktop;
        margin-bottom: 5px;
        line-height: 135%;
    }
    a {
        font-size: $font-desktop;
        color: $blue;
        &:hover {
            color: $dark-blue;
        }
    }
}

.warning-message {
    text-align: center;
    font-size: $font-desktop-small;
    color: $text-color;
    background: rgba($orange, 0.1);
    padding: 0.75rem;
    margin-bottom: 10px !important;
    font-weight: 300 !important;
    h4 {
        font-weight: 500;
        font-size: $font-desktop;
        color: $text-color;
        margin: 0 0 2px;
    }
    p {
        margin: 0;
    }
}

.login-provider-icon {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    font-size: $font-desktop-small;
    text-align: center;
    color: #fff;

    &.facebook {
        background: #4867aa;
    }
    &.gmail {
        background: #dc483c;
    }

    &.vk {
        background: #5e81a8;
    }
    img {
        max-width: 100%;
    }
}

.short-info {
    @extend %shadow;
    padding: 3.5rem 1.5rem 1.5rem;
    max-width: 500px;
    width: 100%;
    margin: 50px auto 0;
    position: relative;
    .short-info__name {
        text-align: center;
        font-weight: 500;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: $font-desktop-large;
        color: $blue;
        margin-bottom: 5px;
    }

    .short-info__registration,
    .short-info__score-total {
        text-align: center;
        font-weight: 300;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: $font-desktop-extra-small;
        color: $light-text-color;
        span {
            margin-right: 3px;
        }
    }
    .short-info__score-total {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .short-info__login {
        text-align: center;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-size: $font-desktop-small;
            color: $blue;
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }
        .login-provider-icon {
            float: none;
            display: inline-block;
        }
    }

    .short-info__avatar {
        position: absolute;
        left: calc(50% - 40px);
        top: -30px;
        width: 80px;
        height: 80px;
        overflow: hidden;
        box-shadow: 0 0 0 5px #fff;
        border-radius: 50%;

        img {
            border-radius: 50%;
            display: block;
            width: 100%;
        }
    }
}

.scoring-detailed {
    list-style: none;
    padding: 0;
    display: flex;

    li {
        text-align: center;
        padding: 10px;
        flex: 0 0 33.333%;
        &.successful {
            svg {
                color: $green;
            }
        }
        &.dispute {
            svg {
                color: $orange;
            }
        }

        &.money {
            svg {
                color: $blue;
            }
        }

        & > span {
            display: block;
        }
        .text {
            font-size: $font-desktop-extra-small;
            color: $light-text-color;
            line-height: 120%;
        }
        .number {
            font-size: $font-desktop-extra-large;
            font-weight: 700;
            color: $text-color;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                font-size: $font-desktop-medium;
            }
        }
    }
}
.scoring-short {
    padding: 0;
    margin: 0;
    font-size: $font-desktop-extra-small;
    list-style: none;
    display: flex;
    margin-top: 3px;
    li {
        display: flex;
        align-items: center;
        color: $light-text-color;
        line-height: 100%;
        span {
            cursor: pointer;
        }
        &.successful {
            svg {
                color: $green;
            }
        }
        &.dispute {
            svg {
                color: $orange;
            }
        }

        &.money {
            svg {
                color: $blue;
            }
        }

        svg {
            display: inline-block;
            margin-right: 2px;
            font-size: $font-desktop-small;
            line-height: 100%;
        }
        &:not(:last-of-type) {
            margin-right: 12px;
        }
    }
}

.info-message-block {
    display: block;
    margin: 0 0 1.5rem;
    padding: 15px;
    background: rgba($orange, 0.1);

    p {
        color: $text-color;
        margin: 0;
        font-size: $font-desktop-extra-small;
        line-height: 145%;

        b {
            color: $blue;
        }
    }
}

.error-block {
    background: rgba($red, 0.05);
    text-align: center;
    padding: 15px;
    border-radius: $border-radius;
    svg {
        color: $red;
        font-size: $font-desktop-large * 2;
    }
    h3 {
        margin: 0;
        color: $red;
        font-weight: 300;
    }
    p {
        color: $text-color;
        font-size: $font-desktop;
    }
}

.user-layout {
    width: 100%;
    padding: 65px 0 25px;
    display: flex;
    justify-content: center;
    //background:lighten($gray, 2%);
    & > .ui.grid {
        margin-top: 0;
        margin-bottom: 0;
    }
    aside {
        margin-right: 30px;
    }

    .user-layout__content-wrapper {
        width: 960px;
        max-width: calc(100vw - 300px);
    }

    .user-layout__sidebar {
        //padding-top: 1rem;
        width: 250px;
        margin-right: 1rem;
        min-width: 190px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            overflow: hidden;
            box-shadow: 0 2px 6px -2px rgba($light-text-color, 0.6);
            &:hover {
                box-shadow: 0 2px 8px -1px rgba($light-text-color, 0.6);
            }
        }
        li {
            padding: 0;
            list-style: none;
            position: relative;
            border-radius: $border-radius;
            a {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                top: 0;
                left: 0;
                height: 50px;
                padding: 0 15px;
                color: $text-color;
                transition: all 0.2s ease-in-out;
                background: #fff;
                span {
                    font-size: $font-desktop;
                    font-family: Roboto, Helvetica, Arial, sans-serif;
                    font-weight: 700;
                }

                .counter-badge {
                    font-family: Roboto, Helvetica, Arial, sans-serif;
                    letter-spacing: -1px;
                    color: $light-text-color;
                    font-weight: 300;
                    background: $gray;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    border-radius: 50%;
                    margin-left: 5px;
                    position: relative;
                    line-height: 19px;

                    small {
                        font-size: 11px;
                        line-height: 0;

                        //position: absolute;
                        //line-height:100%;
                        //left:50%;
                        //top:50%;
                        //transform: translate3d(-50%, -50%, 0);
                    }
                }

                &.active {
                    color: #fff;
                    transition: all 0.2s ease-in-out;
                    background: $blue;
                    border: none;
                    .counter-badge {
                        background: darken($blue, 8%);
                        color: #fff;
                    }
                }
            }
        }
    }
}

.message {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    width: 100%;
    background: #fff;
    max-width: 650px;
    margin: 0 0 10px;
    font-size: $font-desktop-small !important;
    ul {
        margin: 0;
        padding: 0;
        font-size: $font-desktop-small;
        &.inline {
            li {
                display: inline-block;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        li {
            list-style: none;
        }
    }
    a {
        font-size: $font-desktop-small;
        font-weight: 500;
        color: $text-color;
        cursor: pointer !important;
        &.underlined {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    p {
        margin: 0;
        font-size: $font-desktop-small;
        &:not(:last-of-type) {
            margin-bottom: 4px;
        }
        b {
            font-weight: 500;
            margin-left: 3px;
            color: $text-color;
        }
    }
    &.message_centered {
        p {
            text-align: center;
        }
    }
    &.message_primary {
        border: 2px solid $blue;
        p {
            color: $text-color;
            font-weight: 500;
        }
    }
    &.message_success {
        border: 2px solid $green;
        p {
            color: $text-color;
            font-weight: 500;
        }
    }
    &.message_warning {
        border: 2px solid $orange;

        p {
            color: $text-color;
            font-weight: 500;
        }
    }
    &.message_fail {
        background: $red;
        p {
            color: #fff;
            font-weight: 500;
        }
    }
    &.message_attention {
        background: rgba($orange, 0.09);
        p {
            color: $light-text-color;
        }
    }
    &.message_info {
        background: $gray;
        .message__icon {
            color: $blue;
        }
    }

    &__content {
        width: auto;
        flex-grow: 1;
    }
    &__icon {
        margin-right: 10px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        max-width: 200px;
        flex-shrink: 0;
        margin: 0 15px 0 0;

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    &__item-value {
        font-weight: 600;
        margin-left: 5px;
    }

    &__heading {
        font-size: $font-desktop-small;
        color: $blue;
        text-transform: uppercase;
        font-weight: 500;
    }
}

//terms-of-use

.content-header h2 {
    font-weight: 400;
    font-size: 1.7em;
}

.content-text {
    h3,
    h2 {
        font-weight: 400;
    }
}

div.terms-of-use__block {
    p {
        font-weight: 400;
        font-size: 1.01em;
        line-height: 1.4em;
    }
    h3 {
        font-weight: 500;
    }

    ul {
        margin-top: 10px;
        padding-left: 20px;
    }
    ul li {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    ul.personal-info {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }
}

//Badges

span.badge {
    display: inline-block;
    font-size: $font-desktop-extra-small !important;
    border-radius: $border-radius;
    padding: 3px 5px;
    line-height: 115%;
    text-align: center;
    max-width: 100%;

    &.badge_white {
        border: 1px solid $border-color;
        color: $text-color;
        background: #fff;
    }

    &.badge_red {
        background: $red;
        color: #fff !important;
    }
    &.badge_light-red {
        background-color: #ef9a9a;
        border: 1px solid #ef9a9a;
        color: #fff !important;
        height: 22px;
    }
    &.badge_orange {
        background: $orange;
        color: #fff !important;
        justify-content: center;
        svg {
            margin-right: 3px;
        }
    }
    &.badge_blue {
        background: $blue;
        color: #fff !important;
        svg {
            margin-right: 3px;
            color: #fff;
        }
    }
    &.badge_dark-gray {
        background: #8a979e;
        color: #fff !important;
    }
    &.badge_light-gray,
    &.badge_gray {
        background: $gray;
        color: $light-text-color !important;
        justify-content: center;
        border: 1px solid $border-color;
        svg {
            margin-right: 3px;
        }
    }
    &.badge_green {
        background: $green;
        color: #fff !important;
        svg {
            color: #fff;
        }
    }
    &.right-aligned {
        text-align: right;
    }
}

.attachment {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    & + .attachment {
        margin-top: 5px !important;
    }
    .attachment-type {
        font-size: $font-desktop-large;
        color: $spanish-blue;
        margin-right: 2px;
    }
    .attachment-size {
        color: $light-text-color;
        text-transform: uppercase;
        font-size: $font-desktop-extra-small;
        margin: 0 4px;
        flex-shrink: 0;
    }
    .attachment-name {
        cursor: pointer;
        font-weight: bold;
        color: $text-color;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .detach {
        font-size: $font-desktop;
        color: $spanish-blue;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            color: darken($spanish-blue, 4%);
        }
    }
}

.card-form {
    max-width: 500px;
    margin: 0 auto;
    text-align: left;

    h1 {
        font-size: $font-desktop-extra-large;
        color: $blue;
        font-weight: 500;
        text-align: center;
    }

    &__heading {
        font-size: $font-desktop-extra-large;
        font-weight: 500;
        line-height: 1.4;
        color: #3c444e;
    }

    &__fieldset {
        padding: 0;
        margin: 0;
        border: 0;
    }

    &__terms,
    &__secure-terms {
        font-weight: 300;
        font-size: $font-desktop-extra-small;
        margin-bottom: 10px;
        line-height: 130%;
        color: #999;
    }

    &__secure-terms {
        margin-bottom: 6px;
        margin-top: 10px;
        text-align: right;
    }

    &__short-info {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__input-wrap {
        position: relative;
    }

    &__input-icon {
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #696969;
        cursor: pointer;
    }

    &--inputs_500 {
        input {
            font-weight: 500;
        }
    }
}

.autocomplete-list-wrapper {
    position: relative;
    z-index: 10;
    .card-wrapper {
        position: relative;
        & > span {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $light-text-color;
            cursor: pointer;
            &.clear {
                font-size: $font-desktop-extra-small;
                background: $blue;
                color: #fff;
                padding: 3px 4px;
                display: inline-block;
                line-height: 100%;
                border-radius: $border-radius;
                &:hover {
                    background: darken($blue, 5%);
                }
            }
            &.toggle-icon {
                svg {
                    font-size: $font-desktop-large;
                }
            }
        }
    }
    .autocomplete-list {
        -webkit-animation-name: fadeInUpList;
        animation-name: fadeInUpList;
        background: #fff;
        width: 100%;
        right: 0;
        position: absolute;
        border: 1px solid $border-color;
        box-shadow: 0 1px 10px rgba($dark, 0.2);
        bottom: 0;
        z-index: 2;
        transform: translateY(calc(100% - 22px));
        display: none;
        &.animated {
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
        }
        &.visible {
            display: block;
        }
        .autocomplete-list__item {
            padding: 8px 10px;
            color: $text-color;
            margin: 0;
            cursor: pointer;

            &.card {
                display: flex;
                align-items: center;
                img {
                    margin-right: 5px;
                    display: inline-block;
                }
            }

            &:hover {
                background: $gray;
                span {
                    color: $text-color;
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid $border-color;
            }
            p {
                margin: 0;
                font-size: 1rem;
            }
            span {
                color: $light-text-color;
                font-size: $font-desktop-small;
            }
        }
    }
}

.amount {
    &__number {
        margin-left: 10px;
    }
}

.contractTypeDescription {
    margin: 0;
    font-size: 0.98rem;
    text-align: center;
}

.publicOfferLabel {
    position: absolute;
    top: -3px;
    right: 0;
    font-size: 0.8rem;
    background-color: $lighten-blue;
    color: #fff;
    padding: 4px 7px;
    line-height: 1.2;
    border-radius: 2px;

    @include mq(767) {
        top: -24px;
    }
}

.wizardHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.recurrentPayment {
    span[data-react-toolbox='label'] {
        line-height: 1.5;
    }
    @include mq(767) {
        span[data-react-toolbox='label'] {
            font-size: 12px;
            padding-left: 5px;
        }
    }

    @include mq(320) {
        flex-direction: column;
    }

    &__tooltip-icon {
        margin-top: 3px;

        @include mq(767) {
            margin-top: 1px;
        }
    }
}

@import 'landing_responsive';
@import 'site_responsive';
