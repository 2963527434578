@import 'variables';

.UserWizard {
    display: flex;
    align-items: center;

    &-Avatar {
        margin-right: 5px;
        width: 24px;
        height: 24px;

        .user-pic {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &-Name {
        font-size: 0.83rem;
        line-height: 1;
        color: $text-color-gray;
        font-weight: 500;
    }
}
