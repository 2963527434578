$base: 0.25rem;

.expiration-block {
    list-style: none;
    & > * {
        font-size: $font-desktop-extra-small;
    }
    .expiration-date {
        label {
            color: $light-text-color;
        }
        b {
            display: inline-block;
            font-weight: 500;
            font-family: 'Roboto', Vardana, Tahoma, sans-serif;
            color: $dark;
        }
    }

    .prolong-btn {
        display: inline-block;
        font-size: $font-desktop-extra-small;
        cursor: pointer;
        background: $gray;
        color: $text-color;
        border-radius: 3px;
        padding: 4px 5px;
        margin-top: 5px;
        line-height: 100%;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: $blue;
            color: #fff;
        }
    }
}

.user-role-block {
    background: $blue;
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    font-size: $font-desktop;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.user_block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .user-rating {
        display: flex;
        margin-top: 2px;
        & * {
            cursor: default;
        }
    }
    .select-option p {
        display: flex;
        align-items: center;
        font-size: $font-desktop-extra-small;
        font-weight: 500;
    }
    &.right-aligned {
        //flex-direction: row-reverse;
        align-items: flex-end;

        .user_block__wrap {
            flex-direction: row-reverse;
        }
        .payment-method__title {
            text-align: right;
        }
        .select-option {
            justify-content: flex-end;
        }
        .select-option p {
            flex-direction: row-reverse;
        }
        .select-option img {
            margin-right: 0;
            margin-left: 8px;
        }
        .initiator-badge {
            left: 1px;
            right: auto;
        }
        .user_block__pic {
            margin-right: 0;
            margin-left: 10px;
        }
        .user-details {
            align-items: flex-end;
            span.badge {
                cursor: default;
            }
        }
    }

    &__pic {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        .initiator-badge {
            display: flex;
            top: auto;
            position: absolute;
            bottom: 1px;
            right: 1px;
            font-size: $font-desktop-medium * 1.1;
        }
        .user-pic {
            border-radius: 50%;
            text-align: center;
            line-height: 60px;
            background: lighten($gray, 1%);
            color: $light-text-color;
            height: 60px;
            width: 60px;
            display: inline-block;
        }
    }

    &__wrap {
        display: flex;
        align-items: flex-start;
        min-height: 80px;
    }

    &__paymethod {
        margin-top: 14px;
    }
    .user-details {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .user-profile-link {
            display: flex;
            align-items: center;
            span.name {
                margin-right: 5px;
                font-weight: 500;
            }
            span.badge {
                font-size: 0.7rem !important;
                font-weight: 300;
                text-transform: none;
                padding: 2px 4px;
                line-height: 100%;
                position: relative;
                margin: 0;
                display: inline-block;
                cursor: default;
            }
        }

        & > div {
            line-height: 100%;
        }
        .amount {
            color: $text-color;
            font-size: $font-desktop-small;
            font-weight: 500;
            line-height: 110%;
            label {
                font-weight: 300;
                margin: 0 3px 0 0;
                color: $light-text-color;
                font-size: $font-desktop-extra-small;
            }
        }
        .user-name-wrapper {
            display: flex;
            align-items: center;
            svg {
                color: $green;
                font-size: $font-desktop-small;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: darken($green, 3%);
                }
            }
        }

        a {
            font-size: $font-desktop-small;
            font-weight: 300;
            line-height: 120%;
            cursor: pointer;
            &.chat-trigger {
                color: $light-text-color;
                &:hover {
                    text-decoration: underline;
                }
                &.gray {
                    color: $text-color;
                }
            }

            &.user-name {
                color: $text-color;
                margin-right: 5px;
                &.with-link {
                    //text-decoration: underline;
                    &:hover {
                        text-decoration: underline;
                    }
                    cursor: pointer;
                }
            }
        }
        .badge {
            margin: 4px 0;
        }
    }
}

.duration-block {
    text-align: center;
    font-size: $font-desktop-extra-small;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
        margin-right: 5px;
        color: $light-text-color;
    }
    span {
        color: $text-color;
        font-weight: 400;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
}

.info-block {
    width: 100%;
    //display: flex;
    //flex-direction: column;

    flex: 1 0 30%;
    text-align: center;
    & > div {
        line-height: 100%;
    }

    a {
        position: relative;
        line-height: 120%;
        display: inline-block;
        margin-bottom: 5px;
        font-size: $font-desktop-small;
        font-family: 'Roboto', Vardana, Tahoma, sans-serif;
        font-weight: 500;
        .animated-pointers {
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
            height: 20px;
            position: absolute;
            width: $base;
            left: 0;
            top: 2px;
            z-index: 2;
            transform: translateX(-27px) rotate(-90deg);
            .chevron {
                position: absolute;
                width: $base * 2.8;
                height: $base * 0.6;
                opacity: 0;
                transform: scale(0.3);
                animation: move-chevron 3s ease-out infinite;
            }

            .chevron:first-child {
                animation: move-chevron 3s ease-out 1s infinite;
            }

            .chevron:nth-child(2) {
                animation: move-chevron 3s ease-out 2s infinite;
            }

            .chevron:before,
            .chevron:after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 50%;
                background: $blue;
            }

            .chevron:before {
                left: 0;
                transform: skewY(30deg);
            }

            .chevron:after {
                right: 0;
                width: 50%;
                transform: skewY(-30deg);
            }
        }
    }
}

.arrow-container {
    position: relative;
    height: 2px;
    background: $blue;
    width: 200px;
    display: flex;
    align-items: center;
    margin: 6px auto 0;
    &.pointing-left {
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            left: -2px;
            display: block;
            position: absolute;
            top: -4px;
            border-width: 5px 10px 5px 0;
            border-color: transparent $blue transparent transparent;
        }
    }
    &.pointing-right {
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            right: -2px;
            display: block;
            position: absolute;
            top: -4px;
            border-width: 5px 0 5px 10px;
            border-color: transparent transparent transparent $blue;
        }
    }
}

.list-item {
    margin-bottom: 15px !important;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    background: #fff;
    @extend %shadow;
    position: relative;

    &.pending {
        position: relative;
        //border: 2px solid $blue;
        .pending-icon {
            font-size: $font-desktop-medium;
            position: absolute;
            &:before {
                content: '';
                display: inline-block;
                border-style: solid;
                border-width: 18px;
                width: 0;
                height: 0;
                border-color: $blue transparent transparent $blue;
                left: 0;
                right: 0;
                z-index: 3;
            }
            svg {
                color: #fff;
                position: absolute;
                z-index: 4;
                left: 1px;
                top: 3px;
            }
        }
    }
    &.disputed {
        border: 2px solid $orange;
    }
    .divider {
        height: 2px;
        background: url('../images/horizontal_line.svg') repeat-x center;
    }
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        display: block;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        box-shadow: 0 4px 14px 0 rgba(37, 32, 31, 0.25);
    }
    &:hover {
        &:after {
            opacity: 0.8;
        }
    }

    .top {
        &:after {
            content: '';
            display: table;
            clear: both;
        }
        width: 100%;
        & > div {
            float: left;
            padding: 0.8rem 1rem;
            width: 33.3333%;
        }
    }
    .bottom {
        padding: 0.8rem 1rem;
        display: flex;
        justify-content: space-between;
        .controls-block-wrapper {
            display: flex;
        }

        .amount-block {
            text-align: right;

            span {
                background: $blue;
                color: #fff;
                padding: 8px 10px;
                border-radius: 15px;
                font-size: $font-desktop-small;
                font-weight: 500;
                white-space: nowrap;
            }
            label {
                font-weight: 300;
                margin: 0 3px 0 0;
                color: $light-text-color;
                font-size: $font-desktop-extra-small;
            }
        }

        .notification {
            color: $light-text-color;
            font-size: $font-desktop-extra-small;
            background: $gray;
            display: inline-block;
            border-radius: 3px;
            padding: 4px 8px;
            line-height: 110%;
            margin-right: 10px;
            cursor: default;
            &.notification_outgoing {
                background: darken($orange, 2%);
                color: #fff;
            }
            &.notification_incoming {
                background: $blue;
                color: #fff;
                cursor: pointer;

                //& + .prolong-btn {
                //  background: $blue;
                //  color: #fff;
                //  &:hover {
                //    background: darken($blue, 2%);
                //  }
                //}

                svg {
                    color: #fff;
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }
            }
        }
        .controls-block-wrapper {
            a:not(.btn-action) {
                display: inline-block;
                outline: none;
                line-height: 130%;
                color: $light-text-color;
                font-size: $font-desktop-extra-small;
                margin-right: 12px;
                cursor: pointer;
                padding-bottom: 1px;
                border-bottom: 1px solid $light-text-color;
                &:hover {
                    color: darken($light-text-color, 5%);
                    border-bottom-color: darken($light-text-color, 5%);
                }
                //&.chat-btn {
                //  border: none;
                //  position: relative;
                //  span:first-of-type {
                //    //display: inline-block;
                //    //color: $blue;
                //    //padding-bottom: 1px;
                //    //border-bottom: 1px solid $blue;
                //    background-color: #fff;
                //    padding: 5px 14px;
                //    border-radius: 15px;
                //    border: 1px solid $border-color;
                //    transition: all .15s ease-in-out;
                //  }
                //  .counter-badge {
                //    position: absolute;
                //    top: -9px;
                //    right: -6px;
                //    display: inline-block;
                //    background: $blue;
                //    width: 18px;
                //    height: 18px;
                //    border-radius: 50%;
                //    color: #fff;
                //    line-height: 19px;
                //    text-align: center;
                //    font-size: $font-desktop-extra-small * .85;
                //    margin-left: 3px;
                //  }
                //  &:hover {
                //    span:first-of-type {
                //      color: #fff;
                //      background-color: rgba($light-blue, 0.8);
                //      border-color: rgba($light-blue, 0.8);
                //    }
                //  }
                //}
                //&.btn-accept {
                //  padding: 5px 14px;
                //  border-radius: 15px;
                //  color: #fff;
                //  background-color: $btn-accept;
                //  border: 1px solid $btn-accept;
                //  transition: all .15s ease-in-out;
                //  &:hover,
                //  &:focus,
                //  &:active {
                //    color: #fff;
                //    background-color: darken($btn-accept, 5%);
                //    border-color: darken($btn-accept, 5%);
                //  }
                //}
                //&.btn-cancel {
                //  background-color: #fff;
                //  padding: 5px 14px;
                //  border-radius: 15px;
                //  border: 1px solid $border-color;
                //  transition: all .15s ease-in-out;
                //  &:hover,
                //  &:focus,
                //  &:active {
                //    color: #fff;
                //    background-color: rgba($red, 1);
                //    border-color: rgba($red, 1);
                //  }
                //}
                //&.btn-change {
                //  background-color: #fff;
                //  padding: 5px 14px;
                //  border-radius: 15px;
                //  border: 1px solid $border-color;
                //  transition: all .15s ease-in-out;
                //  &:hover,
                //  &:focus,
                //  &:active {
                //    color: #fff;
                //    background-color: rgba($blue, 1);
                //    border-color: rgba($blue, 1);
                //  }
                //}
                &.primary {
                    padding: 5px 14px;
                    border-radius: 15px;
                    background: $blue;
                    color: #fff;
                    transition: all 0.15s ease-in-out;
                    border-bottom: none;
                    &:hover,
                    &:focus,
                    &:active {
                        background: darken($blue, 4%);
                    }
                }
            }
        }
    }

    .public-contracts-count {
        font-size: $font-desktop-extra-small;
        color: $light-text-color;
        margin: 0;

        b {
            font-weight: 500;
            margin-left: 2px;
            color: $blue;
        }
    }

    &:hover {
        .animated-pointers {
            opacity: 1 !important;
        }
    }
    & > .ui.grid {
        & > .column {
            &:nth-child(2),
            &:nth-child(3) {
                padding-left: 0;
                padding-right: 0;
            }
            & > div {
                padding: 15px 0;
            }
        }
    }
    .badge {
        padding: 4px 5px;
        margin: 0;
        display: inline-block;
        border-radius: $border-radius;
        font-size: $font-desktop-small;
        font-weight: 300;
        line-height: 100%;
        cursor: default;
        &.badge_blue {
            background: $blue;
            color: #fff;
        }

        &.badge_gray {
            background: #90a0a7;
            color: #fff;
        }
        &.badge_red {
            background: $red;
            color: #fff;
        }
        &.badge_green {
            background: $green;
            color: #fff;
        }
        &.badge_orange {
            background: $orange;
            line-height: 1.5;
            color: #fff;
        }
    }
}

.control-button {
    &.control-button_green {
        background: $green !important;
        color: #fff !important;
    }
    &.control-button_black {
        background: $dark !important;
        color: #fff !important;
    }
    &.control-button_default {
        background: #fff !important;
        color: #3f4851 !important;
        border: 1px solid lighten(#3f4851, 60%);
    }
    &.control-button_goback {
        background: #efefef !important;
    }
}

.dispute-badge {
    margin-top: 5px;
    display: inline-block;
    background: $red;
    color: #fff;
    font-size: $font-desktop-small;
    padding: 5px 10px;
    border-radius: $border-radius;
    svg {
        margin-right: 3px;
    }
}

.textarea-block {
    width: 100%;
    margin-top: 10px;
    h3 {
        margin-top: 0;
        display: flex;
        align-items: center;
    }
}

.textarea-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.6rem 0 0;
    .link {
        color: $light-blue;
        margin-top: 3px;
        display: inline-block;
        border-bottom: 1px solid $light-blue;
        font-size: $font-desktop-small;
        line-height: 130%;
        &:hover {
            color: $blue;
        }
        &.gray {
            color: $light-text-color;
            border-bottom: 1px solid lighten($light-text-color, 20%);
            &:hover {
                color: $text-color;
            }
        }
    }
}

.timeline {
    display: block;
    position: relative;
    height: 110px;
    width: 100%;
    margin-top: 2.5rem;
    border-top: 4px solid $gray;

    .point {
        top: 0;
        position: absolute;
        .banner {
            position: absolute;
            top: 15px;
            width: 150px;
            font-size: $font-desktop-extra-small;
            color: $light-text-color;
            .important {
                color: $text-color;
                font-weight: 500;
            }
        }
        &.point_deal {
            left: 70%;
            .point-circle {
                background: $orange;
            }
        }
        &.point_contract {
            left: 40%;
            .point-circle {
                background: $blue;
            }
        }
        &.point_now {
            left: 10%;
            .point-circle {
                background: $border-color;
            }
        }
    }

    .point-circle {
        border-radius: 50%;
        top: -9px;
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
    }
}

.buttons {
    p.tip {
        padding: 5px 10px;
        border-radius: $border-radius;
        background: lighten($gray, 2%);
        font-size: $font-desktop-extra-small * 0.95;
        color: $light-text-color;
        line-height: 125%;
        margin-bottom: 5px;
    }
}

@-webkit-keyframes fadeInUpList {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, calc(100% - 20px), 0);
        transform: translate3d(0, calc(100% - 20px), 0);
    }
}

@keyframes fadeInUpList {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, calc(100% - 20px), 0);
        transform: translate3d(0, calc(100% - 20px), 0);
    }
}

[data-react-toolbox='autocomplete'] {
    padding: 0 !important;
}

@keyframes move-chevron {
    25% {
        opacity: 1;
    }
    33.3% {
        opacity: 1;
        transform: translateY($base * 3.8);
    }
    66.6% {
        opacity: 1;
        transform: translateY($base * 5.2);
    }
    100% {
        opacity: 0;
        transform: translateY($base * 8) scale(0.5);
    }
}

.btn-action {
    display: inline-block;
    vertical-align: top;
    outline: none;
    line-height: 130%;
    color: $light-text-color;
    font-size: $font-desktop-extra-small;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 15px;
    padding: 5px 14px;
    border: 1px solid $border-color;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out;

    &--type-alert {
        background-color: rgba(#ffb013, 0.3);
        border-color: transparent;
        color: #e59400;
        font-weight: 800;
        cursor: default;
        &:hover {
            color: darken(#e59400, 2%);
        }
    }

    &--type_cancel {
        background-color: #fff;
        border-color: $border-color;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: rgba($red, 1);
            border-color: rgba($red, 1);
        }
    }

    &--type_accept {
        color: #fff;
        background-color: $btn-accept;
        border-color: $btn-accept;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: darken($btn-accept, 5%);
            border-color: darken($btn-accept, 5%);
        }
    }

    &--type_change {
        background-color: #fff;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: rgba($blue, 1);
            border-color: rgba($blue, 1);
        }
    }

    &--type_chat {
        position: relative;
        background-color: #fff;

        .counter-badge {
            position: absolute;
            top: -9px;
            right: -6px;
            display: inline-block;
            background: $blue;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            color: #fff;
            line-height: 19px;
            text-align: center;
            font-size: $font-desktop-extra-small * 0.85;
            margin-left: 3px;
        }
        &:hover {
            color: #fff;
            background-color: rgba($light-blue, 0.8);
            border-color: rgba($light-blue, 0.8);
        }
    }
    &--type_red {
        background: $red;
        color: #fff;
        border-color: $red;
        &:hover {
            text-decoration: none;
            color: white;
            background: darken($red, 8%);
        }
    }
    &--type_prolong {
        background-color: #fff;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: darken($btn-prolong, 5%);
            border-color: darken($btn-prolong, 5%);
        }
    }

    &--type_dispute {
        background-color: #fff;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: rgba($orange-2, 1);
            border-color: rgba($orange-2, 1);
        }
    }
}
