.about {
    img {
        max-width: 600px;
        width: 80%;
        margin: 0 auto;
        display: block;
    }

    h2 {
        margin: 25px 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 25px 0 0;
        &.simple {
            padding-left: 40px;
        }
        &.numbered {
            & > li {
                margin-bottom: 25px;
            }
            .number {
                width: 25px;
                height: 25px;
                display: inline-block;
                background: $blue;
                color: #fff;
                text-align: center;
                line-height: 25px;
                border-radius: 50%;
                margin-right: 10px;
            }
            ul {
                padding-left: 40px;
                margin-top: 15px;
                li {
                    margin-bottom: 10px;
                }
            }
        }
        li {
            padding: 0;
            margin: 0 0 25px;
        }
    }
    .content-body {
        max-width: 800px;
        margin: 0 auto;
    }
}
