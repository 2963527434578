span#years {
    padding: 3px 5px;
    background: #05659a;
    color: #fff;
    border-radius: $border-radius;
    font-size: $font-desktop;
    margin-bottom: 5px;
    opacity: 0.9 !important;
    display: inline-block;
    vertical-align: middle;
    &:hover {
        background: darken(#05659a, 5%);
    }

    &:after {
        content: '';
        display: inline-block;
        border: solid #fff;
        border-width: 3px 4px 0 4px;
        border-right-color: transparent;
        border-left-color: transparent;
        color: #fff;
        margin-left: 5px;
        top: -3px;
        position: relative;
    }
}
.profile {
    margin: 25px auto;
    .activity-stream {
        .action-link {
            display: inline-block;
            line-height: 100%;
            margin: 0;
            font-size: $font-desktop-small;
            &:hover {
                text-decoration: underline;
            }
        }
        h4 {
            color: $blue;
            font-size: $font-desktop-medium;
        }
    }
    .essential {
        color: $blue;
        font-weight: 500;
    }
    .ui.message {
        border: none !important;
        box-shadow: none;
        background: $gray;
    }

    .secondary {
        font-size: $font-desktop-small;
        &.gray {
            color: $light-text-color;
        }
    }
    .label {
        color: $light-text-color;
        font-weight: 300;
        font-size: $font-desktop;
        margin: 0;
        padding: 0;
    }
    .delete-icon {
        font-size: $font-desktop-small;
        color: $light-text-color;
        line-height: 100%;
        opacity: 0.4;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: $blue;
            opacity: 0.9;
        }
    }
    .small {
        font-size: $font-desktop-small;
    }
    .userName {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    form {
        .form-group {
            position: relative;
            .controls {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                .controls__icon {
                    text-align: center;
                    display: inline-block;
                    outline: none;
                    border: none;
                    padding: 0;
                    text-align: center;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: $spanish-blue;
                    opacity: 0.5;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    line-height: 16px;

                    svg {
                        width: 0.6em;
                        height: 0.6em;
                    }
                    &:hover {
                        background: darken($spanish-blue, 8%);
                        opacity: 1;
                    }
                    & + .controls__icon {
                        margin-left: 3px;
                    }
                }
            }
        }
    }
    .fullNameForm,
    .password-change-form {
        max-width: 95%;
    }
    .addUserName {
        cursor: pointer;
        margin-top: 15px;
        transition: all 0.2s ease-in-out;
        color: $text-color;
        font-size: $font-desktop-small;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $orange;
        padding: 2px 10px;
        border-radius: 15px;
        &:hover {
            background: rgba($orange, 0.15);
        }
        .icon {
            font-size: $font-desktop-medium;
            color: $orange;
            margin: 0;
        }
    }
    .row {
        & > .col {
            margin: 0 auto;
            float: none;
        }
    }
    .profile-info {
        //border: 1px solid $gray;
        //box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.05), 0 8px 10px -8px rgba(0, 0, 0, 0.08);
        //display: flex;
        .content {
            .card-form {
                div[data-react-toolbox='input'] {
                    input {
                        font-size: $font-desktop;
                    }
                }
                .form-inline.expiration-wrapper {
                    width: 60%;
                }
            }
            input {
                font-size: $font-desktop-small;
            }

            &.contact-info {
                font-size: $font-desktop-small;
            }
            &.wallets,
            &.cards {
                .attached-wallets,
                .attached-cards {
                    margin: 10px 0;
                }
                .block__value {
                    img {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
            .block__value {
                color: $text-color;
                display: flex;
                align-items: center;
                .delete-icon {
                    margin-left: 5px;
                }
                &.email-wrapper {
                    label {
                        color: $light-text-color;
                        margin-right: 10px;
                    }
                    color: $blue;
                }
            }
            h3 {
                margin: 0 0 15px;
                font-size: $font-desktop-medium;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                color: $text-color;
            }

            &.social {
                .button-toolbar {
                    margin-top: 15px;
                }
            }
            .action-link {
                cursor: pointer;
                font-size: $font-desktop-small;
                font-weight: 300;
                border-bottom: 1px dashed $blue;
                &:hover {
                    text-decoration: none;
                    color: darken($blue, 10%);
                    border-bottom: 1px dashed darken($blue, 10%);
                }
                & + a {
                    margin-left: 10px;
                }
            }
            .warnings-wrapper {
                & > span {
                    display: flex;
                    align-items: center;
                    svg {
                        color: $orange;
                        margin-right: 3px;
                    }
                }
            }
            .message {
                position: relative;
                margin: 5px auto;
                background: #f3f3f3;
                border-radius: $border-radius;
                font-size: $font-desktop-small;
                padding: 10px 0 10px 35px;
                &.message_success {
                    background: rgba($green, 0.1);
                }
                .content {
                    p {
                        line-height: 140%;
                    }
                }
                .message__icon {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    svg {
                        font-size: $font-desktop;
                        position: absolute;
                        top: 2px;
                    }

                    &.message__icon_advisory {
                        color: $blue;
                    }
                    &.message__icon_error {
                        color: $red;
                    }
                    &.message__icon_warning {
                        color: $orange;
                    }
                    &.message__icon_success {
                        color: $green;
                    }
                }
                & + button {
                    font-size: $font-desktop-small !important;
                    text-transform: none;
                    font-weight: 300;
                    font-family: Roboto, Helvetica, Arial, sans-serif;
                }
            }

            &.user-info {
                font-size: $font-desktop-small;
                .user-pic {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .row {
                    display: flex;
                    margin-bottom: 5px;

                    & > div:first-child {
                        flex: 1 1 40%;
                        padding: 0 10px;
                        text-align: right;
                        color: $light-text-color;
                        line-height: 140%;
                    }

                    & > div:last-child {
                        flex: 1 1 65%;
                        line-height: 140%;
                        &.rating {
                            display: flex;
                        }
                    }
                }
            }
            &.user {
                input[readonly] {
                    color: $light-text-color;
                }
                .user__header {
                    display: flex;
                    .user__avatar {
                        margin-right: 10px;
                    }
                }
                .user__info {
                    margin: 15px 0;
                    label {
                        margin-right: 4px;
                        font-weight: 300;
                        color: $light-text-color;
                    }
                    .user__login {
                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .left,
        .right {
            padding: 25px 35px;
        }
        .left {
            width: 32%;
            flex-shrink: 0;
            border-right: 1px solid $gray;
        }
        .right {
            flex-grow: 1;
        }
    }

    .complex-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .row {
            width: 100%;
            flex-shrink: 0;
            button + button {
                margin-left: 5px;
            }
        }
        .form-group {
            &:first-child {
                width: 100%;
                flex-shrink: 0;
                margin-bottom: 10px;
            }
            &:nth-child(2) {
                flex: 0 1 48%;
            }
            &:nth-child(3) {
                flex: 0 1 48%;
                margin-left: 15px;
            }
        }
    }
    .single-line-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button + button {
            margin-left: 5px;
        }
        .form-group {
            flex: 1 0 50%;
            margin-right: 15px;
        }
    }
}

.x11-link {
    cursor: pointer;
    background: $blue;
    color: #fff !important;
    border-radius: $border-radius;
    padding: 5px 10px;
    font-size: $font-desktop-extra-small;
    display: flex;
    align-items: center;
    line-height: 130%;
    transition: all 0.2s ease-in-out;
    &:hover {
        transition: all 0.2s ease-in-out;

        background: darken($blue, 5%);
    }
    img {
        height: 20px;
        margin-right: 5px;
    }
}
