$pristine-input-bg: #f5f5f5;
$border-radius: 2px;
$input-height: 42px;

$font-desktop-extra-small: 0.8rem;
$font-desktop-small: 0.85rem;
$font-desktop: 0.95rem;
$font-desktop-medium: $font-desktop * 1.1;
$font-desktop-large: $font-desktop * 1.3;
$font-desktop-extra-large: $font-desktop * 1.5;

$h1-size: 2.5rem;
$light-text-color: #696969;
$text-color: #3c444e;
$text-color-gray: #555;
$text-color-light-gray: #c9c9c9;
$blue: #0288d1;
$lighten-blue: #0292e0;
$darken-blue: #016499;
$shadow-blue: #03679e;
$dark-blue: darken($blue, 7%);
$pale-blue: #a3cfe7;
$spanish-blue: #b0c5d7;
$light-spanish-blue: #edf0f4;
$light-blue: #0599ea;
$vk-color: #4a76a8;
$green: #84b749;
$red: #f44336;
$orange: #ffae23;
$orange-2: #ff9800;
$gray: #f0f1f3;
$dark: #3c4a5a;

$border-color: #dadce0;
$btn-accept: #43a047;
$btn-prolong: #8a979e;

%blueGradient {
    background: $blue; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, $blue, darken($blue, 2%));
    background: linear-gradient(to bottom, $blue, darken($blue, 2%));
}

%grayGradient {
    background: $gray; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #fff, $gray);
    background: linear-gradient(to bottom, #fff, $gray);
}

// Breakpoints mixins (max-width)
//============================================

@mixin mq($screen) {
    @media (max-width: $screen +'px') {
        @content;
    }
}
