.block-loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.content-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;

    .loader {
        width: 40px;
        height: 40px;
        border: 3px solid $border-color;
        background: #fff;
        border-radius: 50%;
        position: relative;
        -webkit-animation: roll 1.4s linear infinite;
        -moz-animation: roll 1.4s linear infinite;
        -ms-animation: roll 1.4s linear infinite;
        -o-animation: roll 1.4s linear infinite;
        animation: roll 1.4s linear infinite;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 15px;
            left: 0;
            top: -3px;
            transform: rotate(45deg);
            background: #fff;
        }
    }
}

@keyframes roll {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
