@import 'variables';

.flex {
    display: flex;
}

.fd-column {
    flex-direction: column;
}
.fd-row-reverse {
    flex-direction: row-reverse;
}
.fd-column-reverse {
    flex-direction: column-reverse;
}

.fw-wrap {
    flex-wrap: wrap;
}
.fw-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.jc-start {
    justify-content: flex-start;
}
.jc-end {
    justify-content: flex-end;
}
.jc-center {
    justify-content: center;
}
.jc-between {
    justify-content: space-between;
}

.ac-start {
    align-content: flex-start;
}
.ac-end {
    align-content: flex-end;
}
.ac-center {
    align-content: center;
}

.ai-start {
    align-items: flex-start;
}
.ai-end {
    align-items: flex-end;
}
.ai-center {
    align-items: center;
}

.fg-2 {
    flex-grow: 2;
}

.pb-0 {
    padding-bottom: 0;
}
.pb-16 {
    padding-bottom: 16px;
}
.pb-32 {
    padding-bottom: 32px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-32 {
    margin-top: 32px;
}
.mr-8 {
    margin-right: 8px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-32 {
    margin-bottom: 32px;
}
.ml-8 {
    margin-left: 8px;
}

.ta-justify {
    text-align: justify;
}
.ti-24 {
    text-indent: 24px;
}

.point {
    cursor: pointer;
}
.underline {
    text-decoration: underline;
}
.bold {
    font-weight: 700 !important;
}
.medium {
    font-weight: 500 !important;
}
.regular {
    font-weight: 400 !important;
}
.link {
    @extend .point;
    color: $light-blue;
}
.blue {
    color: $blue;
}
.red {
    color: $red;
}

.small-text {
    font-size: 11px;
    line-height: 11px;
}

.tooltip-icon {
    min-width: 14px;
    min-height: 14px;
    margin-left: 4px;
    cursor: pointer;
    color: $border-color;
    font-size: 14px !important;

    &:hover {
        color: $green;
    }
}

.copy-icon,
.vk-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 8px;
    flex-shrink: 0;
}

.copy-icon {
    background-color: $border-color;
    font-size: 14px;
    color: $text-color-gray;

    &:hover {
        background-color: darken($border-color, 10%);
    }
}

.vk-icon {
    background-color: $vk-color;
    color: #fff;

    &:hover {
        background-color: darken($vk-color, 10%);
    }
}

.no-mb {
    margin-bottom: 0 !important;
}

.quantitative-offer__checkbox {
    span[data-react-toolbox='label'] {
        white-space: normal;
        word-break: break-word;
        word-wrap: break-word;
        display: inline-flex;
    }
}
