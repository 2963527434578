.support-widget {
    position: fixed;
    z-index: 100;
    right: 15px;
    bottom: 15px;

    .support-dialog {
        background: #fff;
        border-radius: 15px;
        width: 380px;
        box-shadow: 0 2px 15px rgba($dark, 0.5);
        overflow: hidden;
        min-height: 400px;
        max-height: calc(100vh - 80px);

        .support-dialog__header {
            background: $green;
            padding: 10px;
            border-bottom-right-radius: 15px;
            position: relative;
            .close-btn {
                position: absolute;
                right: 10px;
                top: 10px;
                color: #fff;
            }
            h4 {
                margin: 0;
                color: #fff;
                font-weight: 400;
                font-size: $font-desktop-medium;
            }
            p {
                font-size: $font-desktop-extra-small;
                color: rgba(white, 0.8);
                display: flex;
                align-items: center;
                margin: 0;
            }
            .indicator {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: red;
                border-radius: 50%;
                margin-right: 5px;
            }
        }

        .support-dialog__messages {
            background: #fff;
            padding: 5px;
            border-radius: $border-radius;
            overflow-y: auto;
            max-height: 60vh;
            min-height: 250px;

            .dialog-message__wrapper {
                width: 100%;
                &:before,
                &:after {
                    display: table;
                    content: '';
                    clear: both;
                }
            }

            .dialog-message {
                padding: 10px;
                font-size: $font-desktop-small;
                line-height: 135%;
                max-width: 65%;
                color: #fff;
                font-family: 'Gotham Pro', Roboto, sans-serif;
                font-weight: 500;
                margin-bottom: 10px;
                p {
                    margin: 0;
                }
                .dialog-message__date {
                    font-family: Roboto, Helvetica, Arial, sans-serif;
                    font-size: 10px;
                    text-align: right;
                }
                &.dialog-message_outgoing {
                    background: rgba($spanish-blue, 0.2);
                    color: $dark;
                    float: right;
                    border: {
                        top-left-radius: 18px;
                        bottom-left-radius: 18px;
                        top-right-radius: 18px;
                    }
                    .dialog-message__date {
                        color: $light-text-color;
                    }
                }
                &.dialog-message_incoming {
                    background: $dark;
                    float: left;
                    color: #fff;
                    border: {
                        top-left-radius: 18px;
                        bottom-left-radius: 0;
                        top-right-radius: 18px;
                        bottom-right-radius: 18px;
                    }
                    .dialog-message__date {
                        color: #fff;
                        text-align: left;
                    }
                }
            }
        }
        .support-dialog__form {
            padding: 10px;
            border-top: 1px solid $border-color;
            input {
                border-radius: 15px;
                outline: none;
            }
            .dialog-textbox-wrapper {
                position: relative;
                .emoji-picker {
                    position: absolute;
                    bottom: 4px;
                    display: none;
                    @extend .animated;
                    &.opened {
                        display: block;
                        @extend .fadeInUp;
                    }
                    //&.closed{
                    //  display: block;
                    //  @extend .fadeOutDown;
                    //}
                }
                .textbox-controls {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 7px 10px 10px 5px;
                    .emoji-open {
                        color: $border-color;
                        margin-right: 5px;
                        font-size: $font-desktop-extra-large;
                    }
                    .send {
                        color: $blue;
                        font-size: $font-desktop-large;
                    }
                }
                .emoji-mart-search {
                    display: none;
                }
                .emoji-mart-scroll {
                    height: 150px;
                }

                .dialog-textbox {
                    border: none;
                    resize: none;
                    outline: none;
                    box-shadow: none;
                    font-family: 'Gotham Pro', Roboto, sans-serif;
                    color: $dark;
                    height: 50px;
                    padding-right: 50px;
                }
            }
            & > div {
                margin-bottom: 5px;
            }
            label {
                display: block;
                font-size: $font-desktop-extra-small;
                color: $light-text-color;
            }
            input,
            textarea {
                display: block;
                width: 100%;
                border: 1px solid $border-color;
                padding: 7px 10px;
                font-size: $font-desktop-small;
                color: $text-color;
                font-weight: 300;
                font-family: Roboto, Helvetica, Arial, sans-serif;
            }

            textarea {
                min-height: 80px;
                line-height: 130%;
            }
        }
    }

    .support-trigger {
        background: $green;
        padding: 8px 15px;
        position: relative;
        bottom: -15px;
        display: flex;
        align-items: center;
        font-family: 'Gotham Pro', Roboto, sans-serif;
        color: $dark;
        svg {
            margin-right: 5px;
        }
        border: {
            top-left-radius: 15px;
            top-right-radius: 15px;
        }
    }
}
