.ui.pagination {
    padding: 0;
    margin: 0;

    & > li {
        display: inline;
        cursor: pointer;
        a {
            padding: 5px 10px;
            border-radius: $border-radius;
            display: inline-block;
        }
        &.break {
            margin: 0 15px;
        }
        &.selected > a {
            background: $border-color;
            border-color: $border-color !important;
            color: $light-text-color;
            outline: none;
            cursor: default;
        }
        &.disabled {
            cursor: default;
            & > a {
                border-color: $border-color;
                color: rgba($light-text-color, 0.6);
                &:hover {
                    color: rgba($light-text-color, 0.6);
                }
            }
        }
        & > a {
            float: none;
            margin: 0;
            font-size: $font-desktop;
            font-weight: 300;
            color: $light-text-color;
            border-color: $border-color;
            &:active,
            &:focus {
                outline: none !important;
            }
        }
    }
}
