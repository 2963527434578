/* This stylesheet generated by Transfonter (https://transfonter.org) on September 13, 2017 3:46 PM */

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.ttf');
    src: local('Gotham Pro'), local('GothamPro'),
        url('../fonts/GothamPro.ttf') format('truetype'),
        url('../fonts/GothamPro.svg#GothamPro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Bold.ttf');
    src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
        url('../fonts/GothamPro-Bold.ttf') format('truetype'),
        url('../fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
    font-weight: 400;
    font-style: normal;
}
