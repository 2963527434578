footer {
    width: 100%;
    padding: 2rem 0;
    color: $text-color;
    font-family: 'Roboto', Vardana, Tahoma, sans-serif;
    border-top: 1px solid $border-color;

    .copyright,
    .support {
        margin: 0;
        color: $light-text-color;
        font-size: $font-desktop-small;
        font-family: 'Roboto', Vardana, Tahoma, sans-serif;
    }
    .support {
        margin-bottom: 0.5rem;
        a {
            cursor: pointer;
        }
    }

    .payment-methods {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 0 1rem;
        li {
            width: 50px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                max-height: 100%;
            }
            &.bitcoin {
                width: 35px;
                height: 35px;
            }
            &:first-child {
                width: 100px;
                img {
                    display: inline-block;
                    width: 100px;
                }
            }
        }
    }
    .footer-content {
        display: flex;
        justify-content: space-between;

        .footer__menu {
            margin-bottom: 1rem;

            a {
                font-size: $font-desktop-small;
                text-decoration: none;
                transition: color 0.2s ease-in-out;
                font-weight: 300;
                color: $light-text-color !important;
                margin-bottom: 5px;
                font-family: 'Roboto', Vardana, Tahoma, sans-serif;
                display: inline-block;
                &:hover {
                    color: $blue !important;
                }
                &.active {
                    color: $blue !important;
                    font-weight: 500;
                }
                & + a {
                    margin-left: 45px;
                }
            }
        }
    }
    .language-dropdown {
        width: 150px !important;
        display: inline-block;
        img {
            margin-right: 6px;
            display: inline-block;
            float: left;
            width: 32px;
            height: 32px;
            & + span {
                line-height: 32px;
            }
        }
        ul {
            text-align: left;
            width: 200px;
            li {
                span {
                    color: $text-color;
                }
            }
        }
        & > div {
            margin: 0;
            padding: 0;
            text-align: left;
        }
        span {
            color: $text-color;
            font-size: $font-desktop;
        }
    }
}
