.dialog {
    position: relative;
    overflow: inherit;

    h2 {
        font-size: $font-desktop-large;
        color: $blue;
    }

    .dialog__controls-block {
        text-align: right;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        padding: 8px 8px 0 0;
        .close-dialog {
            font-size: $font-desktop-extra-large;
            opacity: 0.45;
            cursor: pointer;
            &:hover {
                opacity: 0.9;
            }
        }
    }

    .dialog__body {
        max-height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &:not(.passport-form) {
        max-width: 450px !important;
    }
    &.add-purse {
        width: 450px !important;
        max-width: 90vw !important;
    }

    ul.entity-details-list {
        margin: 0 0 10px;
        list-style: none;
        font-size: $font-desktop-small !important;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
            color: $text-color;
            b {
                font-weight: 500;
                color: $blue;
                margin-left: 5px;
            }
        }
    }

    h1 {
        margin: 0 0 15px;
        font-size: $font-desktop-medium !important;
        font-weight: 300;
        color: $blue;
    }

    &.prolong-deal-request {
        .column {
            & > div {
                width: 100px !important;
            }
        }
    }
    &.accept-contract {
        max-width: 600px !important;
    }
    &.contract-agreement,
    &.template-filled,
    &.contract-agreement-generated {
        min-width: 50vw !important;
        max-width: 800px !important;
    }

    &.prolong-deal-confirmation {
        text-align: center;
        .text-block {
            font-size: $font-desktop;
            font-weight: 300;
            color: $light-text-color;
            margin-bottom: 15px;
            line-height: 125%;
            cursor: default;
            svg {
                margin-right: 4px;
                position: relative;
                opacity: 0.35;
                font-size: $font-desktop-medium;
            }
            b {
                //color:$blue;
                font-weight: 300;
                border-bottom: 1px dashed lighten($light-text-color, 25%);
            }
            .date-badge {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-desktop;
                font-weight: 500;
                color: $blue;
                margin-top: 5px;
                font-family: 'Gotham Pro', Roboto, sans-serif;
            }
        }
    }

    &.contract-agreements-list {
        min-width: 45vw !important;
        max-width: 600px !important;

        .template-categories {
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            max-height: 80vh;
            margin-top: 10px;
            .template-category {
                padding: 10px;
                border: 1px solid $border-color;
                display: block;
                flex: 0 1 31%;
                min-height: 80px;
                font-weight: 500;
                margin-bottom: 10px;
                &:hover {
                    box-shadow: inset 0 0 0 1px $blue;
                    border-color: $blue;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                }
                h3 {
                    font-size: $font-desktop;
                    color: $text-color;
                    margin: 0 0 3px;
                    line-height: 130%;
                }
                p {
                    font-size: $font-desktop-extra-small;
                    color: $light-text-color;
                    font-weight: 300;
                    line-height: 130%;
                }
                &:not(:nth-child(3n)),
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }

    &.counter-offer {
        width: 35vw !important;
        min-width: 300px;
        .dialog__body {
            p {
                font-weight: 300;
                margin-bottom: 10px;
            }
        }
    }
    &.passport-form {
        width: 40vw !important;
        min-width: 700px;
        .form-inline {
            display: flex;
            .form-group {
                flex: 1 1 35%;
                &.small {
                    flex-grow: 0;
                }
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    .dialog__body {
        h1 {
            font-size: $font-desktop-large !important;
            &.centered {
                text-align: center;
            }
        }
        .form-buttons-group {
            &.centered {
                text-align: center;
            }
            a {
                display: inline-block;
                font-size: $font-desktop-small;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .helper-text {
            line-height: $font-desktop-small * 1.4 !important;
            font-weight: 300 !important;
            font-size: $font-desktop-small !important;
            b {
                margin: 0 3px;
                font-weight: 500 !important;
            }
        }
    }

    .help-dialog,
    .registration-dialog {
        a {
            cursor: pointer;
        }
        h1,
        p {
            text-align: center;
        }
        h1 {
            margin: 20px auto 10px;
        }
        p {
            margin-bottom: 25px !important;
            line-height: 150%;
            color: $light-text-color;
            font-weight: 300;
        }
    }
}

.rhombus-icon {
    width: 65px;
    height: 65px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-desktop-large * 1.35;
    margin: 0 auto 15px;

    svg,
    span {
        //position: absolute;
        z-index: 2;
        &.green {
            color: $green;
        }
        &.blue {
            color: $blue;
        }
        &.red {
            color: $red;
        }
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $pristine-input-bg;
        transform: rotate(45deg);
        border-radius: 15px;
        z-index: 1;
    }
}

.icon {
    margin-top: 2em;
    font-size: 4em;
}

.icon-enter {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
}

.icon-enter.icon-enter-active {
    opacity: 1;
}

.icon-leave {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.icon-leave.icon-leave-active {
    opacity: 0.01;
    height: 0;
    width: 0;
}

.form-group__textarea span {
    line-height: 150%;
    font-size: $font-desktop-small;
}
