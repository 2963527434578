@import './variables';

.btn-red {
    background-color: $red !important;
    color: white !important;
}

.esc-badge {
    display: inline-block;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 14px;
    color: #fff;
    background-color: #d9d9d9;
    border-radius: 50%;

    &.active {
        background-color: $blue;
    }

    &.done {
        @extend .flex;
        @extend .ai-center;
        @extend .jc-center;
        background-color: $green;
    }
}

span.code {
    background-color: #d9d9d9;
    padding: 4px 8px;
    height: 24px;
    line-height: 24px;
    border-radius: 3px;
}

div.esc-helper-text {
    color: $text-color-gray;
    border-radius: 3px;
    background-color: $gray;
    padding: 8px;

    @media only screen and (max-width: 767px) {
        font-size: 14px;
    }

    &.center {
        text-align: center;
    }
}

div.esc-button-group {
    display: flex;
    flex-flow: row wrap;
    margin-top: 32px;

    & > *:not(:last-child),
    div.buttons > *:not(:last-child) {
        margin-right: 8px;
    }

    & > *,
    div.buttons > * {
        margin-bottom: 8px;
    }

    & > *:last-child {
        margin-left: auto;
    }
}
